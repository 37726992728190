/************* library page**************/
.ibrary-home {
    position: relative;

    &-search {
        @include flex-row;
        flex-wrap: wrap;
        position: absolute;
        background-color: #fff;
        top: 42px;
        width: 100%;
        z-index: 20;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    }

    &-content {
        height: 100%;
        background-color: #fff;
        border-radius: 15px;
        margin-top: 75px;
        position: relative;
    }

    &-swiper {
        top: -50px;

        &-item {
            @include flex-col;
        }
    }

    &-summary {
        font-size: 12px;
        color: $text-secondary;
        padding: 5px 0;
        padding-left: 20px;
        text-align: justify;
        margin: 0;
    }

    &-info {
        font-size: 14px;
        color: #000;
        padding: 5px 0;
        padding-left: 20px;
        text-align: justify;
        margin: 0;
    }

    &-card {
        @include flex-row;

        &-image {
            width: 175px !important;
            height: 250px !important;
            border-radius: 10px;
            box-shadow: $shadow;
        }

        &-info {
            @include flex-col;
            align-self: flex-end;
            width: 100%;
            height: 100%;
            margin: 5px;
        }

        &-title {
            color: #000 !important;
            font-size: 14px;
            font-family: 'jamalBold';
        }

        &-author {
            font-size: 12px;
            color: $text-secondary !important;
        }

        &-but {
            font-size: 14px;
            width: 75px;
            border: 1px solid $main-color;
            border-radius: 15px;

            &:hover {
                background-color: $main-color;
                color: #fff;
            }
        }
    }

    &-author {
        @include flex-col;

        &-card {
            @include flex-row;
            align-items: center;
            margin: 5px;

            p {
                color: #000;
                font-size: 14px;
                margin: 5px;
            }
        }
    }

    &-books {
        @include flex-row;
        flex-wrap: wrap;
        margin: 5px;

        &-card {
            @include flex-row;
            height: 100%;
            margin: 5px 0;

            &-image {
                width: 100px !important;
                height: 150px;
                box-shadow: $shadow;
                border-radius: 5px;

            }

            &-info {
                @include flex-col;
                flex: 1;
                justify-content: center;
                margin: 5px;
            }

            &-title {
                color: #000 !important;
                font-size: 14px;
                margin: 2px 0;
                font-family: 'jamalBold';
            }

            &-author {
                font-size: 12px;
                color: $text-secondary !important;
            }

            &-but {
                font-size: 14px;
                width: 75px;
                border: 1px solid $main-color;
                border-radius: 15px;
                align-content: flex-end;


                &:hover {
                    background-color: $main-color;
                    color: #fff;
                }
            }
        }
    }
}

.book-item {
    &-swiper {
        background: #fff;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    }

    &-header {
        @include flex-row-between-start;
        background-color: #fff;
        margin-top: 20px;
        padding-bottom: 25px;
        border-radius: 15px;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);


        &-list {
            @include flex-col-center;

            @include for-size(lg) {
                @include flex-row-center;
            }
        }

        &-images {
            @include flex-col-center;
            margin: 15px;

            @include for-size(md) {
                margin: 5px;
            }

            @include for-size(sm) {
                margin: 0;
            }

            .active-cover {
                border: 2px solid $main-color;
                transition: all 0.3s linear;
            }

            img {
                width: 75px;
                height: 100px;
                object-fit: cover;
                padding: 5px;
                border-radius: 5px;
                border: 2px solid transparent;
            }
        }

        &-book {
            @include flex-row;

            @include for-size(md) {
                @include flex-col-center;
            }

            &-image {
                width: 300px;
                height: 450px;
                border-radius: 15px;
                box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
            }

            &-info {
                @include flex-col-between-start;
                margin: 5px 50px;
                width: 100%;

                @include for-size(md) {
                    @include flex-col-between-center;
                    margin: 5px 20px;
                }

                &-names {
                    @include flex-col-between-start;

                    @include for-size(md) {
                        @include flex-col-between-center;
                    }
                }

                &-author {
                    color: $text-secondary;
                    font-size: 14px;
                    padding: 0 5px;
                }

                &-type {
                    background-color: $main-color;
                    color: #fff;
                    margin: 0 5px;
                    padding: 5px;
                    width: 50px;
                    border-radius: 10px;
                    text-align: center;
                }

                &-read {
                    color: $text-secondary;
                    font-size: 14px;
                    padding: 8px;
                }

                &-summary {
                    color: $text-secondary;
                    text-align: justify;
                    margin-top: 20px;
                }

                &-buts {
                    @include flex-row;

                    &-download {
                        @include main-button;
                        margin-right: 5px;
                        border-radius: 20px;
                    }

                    &-read {
                        @include main-button;
                        width: 250px;
                        border-radius: 20px;

                        @include for-size(sm) {
                            width: 175px;

                        }
                    }
                }
            }
        }
    }

    &-tab {
        border: none;

        @include for-size(sm) {
            align-items: center;
            justify-content: center;
            padding: 5px;
        }

        .nav-link {
            color: #000 !important;

            @include for-size(sm) {
                font-size: 12px !important;
            }
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            color: #000 !important;
            border: none !important;
            border-bottom: 2px solid $main-color !important;
        }

    }



}

.author-info {
    @include flex-row;

    @include for-size(sm) {
        @include flex-col-center;
        text-align: center;
    }

}

.card-list {
    @include flex-row-between-start;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
}



/************* library page**************/