.swiper-bg {
    position: relative;
    overflow: hidden;

    &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(to right, #f9fafb, transparent);
        width: 150px;
        height: 100%;
        z-index: 3;
    }

    &::before {
        content: ' ';
        position: absolute;
        right: 0;
        top: 0;
        background-image: linear-gradient(to left, #f9fafb, transparent);
        width: 150px;
        height: 100%;
        z-index: 3;
    }
}