.f-bg-color {
    font-family: "jamalBold";
    font-size: 50px;
    padding-top: 50px;
    background: linear-gradient(to bottom, #6366f1, #3b82f6, #10b981);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.f-description {
    color: #747474;
    line-height: 25px;
    font-size: 18px;
    text-align: justify;
    padding-top: 25px;

    &::marker {
        color: $main-color;
    }
}

.f-link-color {
    color: $main-color;
}

.speak-up {
    background-color: $main-color ;
    padding: 25px 0px;
    padding-bottom: 100px;

    &-form {
        position: relative;
        top: -100px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: $shadow;
        padding: 20px;
    }
}
.applications-image {
    @include flex-col-center;

    img {
        width: 75%;

    }
}

.applications-buts {
    @include flex-row-center;
    flex-wrap: wrap;
    direction: ltr;

    a {
        background-color: $main-color;
        color: #fff !important;
        width: 150px;
        border-radius: 100px;
        text-align: center;
        padding: 2px 10px;
        margin: 5px;

        &:hover {
            background-color: #25d8c0;
        }
    }
}

.applications-text {
    @include flex-col-center;

    p {
        padding: 10px;
        width: 50%;
        text-align: center;
    }
}