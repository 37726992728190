@import './global/index';
@import './custom/index';
@import './views/index';

:root {
    --body-color: #f8f8f8;
    --swiper-navigation-color: #aaa;
}

body {
    margin: 0;
    font-family: 'jamalRegular' !important;
    direction: rtl !important;
    text-align: right !important;
    background-color: var(--body-color);
    overflow-x: hidden !important;
}

.App {
    min-height: 100vh;
    overflow-x: hidden !important;

    @include for-size(xl) {
        margin-top: 65px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*-----------------------------
scrollbar
-------------------------------*/
::-webkit-scrollbar {
    margin: 15px;
    width: 6px;
    height: 5px;
    cursor: pointer !important;
}

::-webkit-scrollbar-track {
    background-color: #eee;
    width: 5px;
    cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
    background: $main-color;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    width: 5px;
    cursor: pointer;
}