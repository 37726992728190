.more-pagination {
    @include flex-col-center;
    position: relative;
    margin-top: -95px;
    background-image: linear-gradient(to top, #fff, #fff, transparent);
    z-index: 2;
    height: 150px;

    button {
            @include flex-row-center;
        @include main-button;
    }
}