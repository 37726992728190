/************* remains page**************/
.remains-page {
    &-card {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: $shadow;
        padding: 15px;
        margin: 15px !important;
    }

    &-search {
        position: absolute;
        top: 10px;
        z-index: 50;

        .search-item {
            @include flex-col-center;
            padding: 5px;

            .neme {
                font-size: 12px;
                color: #000;
            }
        }

        &-content {
            @include flex-row;
            flex-wrap: wrap;
            position: absolute;
            background-color: #fff;
            top: 42px;
            width: 50%;
            z-index: 20;
            padding: 10px;
            border-radius: 15px;
            box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        }

    }

    &-swiper {
        width: 100%;
        height: 500px;

        @include for-size(sm) {
            height: 350px;
        }

        .swiper-card {
            @include flex-row;
            background-color: $main-color;
            background-image: linear-gradient(to right, $linear);
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            .image-content {
                @include flex-col;
                position: relative;
                justify-content: flex-end;
                overflow: hidden;

                .info-content {
                    position: relative;
                    height: 350px;
                    width: 400px;
                    overflow: hidden;

                    @include for-size(md) {
                        height: 250px;
                        width: 300px;
                    }

                    @include for-size(sm) {
                        height: 150px;
                        width: 200px;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;


                    }

                    .subtitle {
                        position: absolute;
                        bottom: 0;
                        right: 20px;
                        color: #fff;
                        z-index: 10;

                        p:first-child {
                            font-size: 15px;
                            margin: 5px 0;

                            @include for-size(md) {
                                font-size: 13px;
                            }

                            @include for-size(sm) {
                                font-size: 12px;
                            }
                        }

                        p:last-child {
                            width: calc(100% - 25px);
                            font-size: 12px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            @include for-size(md) {
                                font-size: 10px;
                            }

                            @include for-size(sm) {
                                display: none;
                            }
                        }
                    }

                    .bg-cover {
                        @include absolute-object();
                        background-color: #00000075;
                    }
                }


            }

            .text-content {
                margin: 150px 0 0 0;
                z-index: 15;
                position: relative;
                right: -35px;

                .icon-content {
                    color: #fff;
                    font-size: 75px;

                }

                .text {
                    color: #fff;
                    font-size: 25px;
                    width: 100%;



                }
            }
        }

        .img-top {
            position: absolute;
            top: -75px;
            left: -150px;
            width: 300px;
            height: 300px;
            transform: rotate(90deg);
            z-index: 10;
            object-fit: cover;
            opacity: 0.3;


        }

    }

    &-swiper-quote,
    .card-quote-content {
        padding-top: 50px;
        position: relative;
        color: #000;

        .card-quote {
            @include flex-col-between-center;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: $shadow;
            width: 100%;
            height: 300px;

            .info-content {
                @include flex-col-between-center;
                width: 100%;
                height: 100%;

                .avatar {
                    margin-top: -50px;
                    box-shadow: $shadow;
                }

                .icon-content {
                    @include flex-col-center;
                    @include absolute-object;
                    font-size: 150px;
                    color: rgba(0, 0, 0, 0.1);
                    z-index: 1;
                    opacity: .5;

                }

                .text {
                    font-size: 18px;
                    text-align: center;
                    z-index: 2;
                    padding: 0 15px;
                    color: #000;
                }

                .subtitle {
                    font-size: 14px;
                    color: $text-secondary;

                    p {
                        &:last-child {
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    .images-remind-layout {
        position: absolute;
        margin: 0;
        padding: 0;
        left: 50px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(125px, 2fr));
        grid-auto-rows: 129px;
        grid-gap: 3px;
        grid-auto-flow: row dense;
        justify-content: center;
        width: 35%;

        @include for-size(md) {
            left: 10px;
        }

        @include for-size(sm) {
            left: 5px;
        }

        .layout-images {
            display: grid;
            justify-content: space-evenly;
            align-items: center;
            grid-row: span 1;

            &:nth-child(even) {
                -webkit-grid-column: span 3;
                grid-column: span 2;
                grid-row: span 2;

                @include for-size(md) {
                    -webkit-grid-column: span 2;
                    grid-column: span 1;
                    grid-row: span 1;
                }

                @include for-size(sm) {
                    -webkit-grid-column: span 1;
                    grid-column: span 1;
                    grid-row: span 1;
                }
            }
        }


        .img-layout {
            opacity: 0.6;
            padding: 5px;
            height: 50px;

        }

    }
}

/************* remains page**************/