.iraq-map {
    width: 100%;
    padding: 15px;
    position: relative;
}

.svg-iraq {
    >path {
        fill: #68B984;
    }
}

.svg-map {
    stroke: white !important;
}

.map-tooltip {
    position: fixed;
    width: 100px;
    padding: 10px;
    margin: 5px;
    border-radius: 15px;
    border: 1px solid #fff;
    background-color: #b8e2b3;
    color: #fff;
    text-align: center;
    z-index: 1000;
}