/************* news page**************/


.search-news {
    margin: 5px 0;

    &-card {
        border-radius: 5px;
        height: 50px;
        width: 100%;
        color: #000;
        background-color: $color-4;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 10%);
        margin: 5px 0;
        border: none;
        text-align: right;

        &:hover {
            color: $main-color;
            background-color: #fff;
        }
    }

    &-title {
        font-size: 12px;
       text-overflow: ellipsis;
       overflow: hidden; 
       white-space: nowrap
    }
}

.news-category {
    @include flex-row;

    &-title {
        padding: 0 5px;
        font-size: 14px;
    }

    &-radio-unactive {
        width: 15px;
        height: 15px;
        border-radius: 25px;
        border: 1px solid #c4c3c3;
        padding: 0;
    }

    &-radio-active {
        width: 15px;
        height: 15px;
        border-radius: 25px;
        background-color: $main-color;
        border: 1px solid #c4c3c3;
        padding: 0;
    }
}

.carousel-news {
    width: 100%;
    height: 500px;
    border-radius: 15px !important;
    overflow: hidden;
    position: relative;

    &-image {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    &-summary {
        font-size: 14px;
    }
}

.news-item-content {
    @include flex-col;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);

    &-header {
        @include flex-col;
        width: 100%;
        height: 600px;
        position: relative;
        overflow: hidden;
        border-radius: 15px 15px 0 0;
        border-bottom: 5px solid $main-color;

        @include for-size(md) {
            height: 400px;
        }

        @include for-size(sm) {
            height: 300px;
        }
    }

    &-image {
        position: absolute;
        width: 100%;
        height: 600px;
        object-fit: cover;

        @include for-size(md) {
            height: 400px;
        }

        @include for-size(sm) {
            height: 300px;
        }
    }

    &-title {
        font-size: 20px;
        color: $main-color;
        padding: 25px 10px;
        font-family: 'jamalBold';

        @include for-size(md) {
            font-size: 18px;
        }

        @include for-size(sm) {
            font-size: 15px;
        }
    }

    &-carousel {
        width: 90%;
        height: 500px;
        border-radius: 15px;
        overflow: hidden;

        @include for-size(md) {
            height: 300px;
        }

        @include for-size(sm) {
            height: 200px;
            width: 100%;
        }

        img {
            width: 100%;
            height: 500px;
            object-fit: cover;

            @include for-size(md) {
                height: 300px;
            }

            @include for-size(sm) {
                height: 200px;
            }
        }
    }

    &-summary {
        width: 90%;
        font-size: 16px;
        padding: 10px 0;
       text-align: justify;

        @include for-size(sm) {
            font-size: 15px;
            width: 100%;
        }
    }

    .news-card-info {
        height: 100%;
        padding: 10px 50px;
        justify-content: flex-end;
        z-index: 10;
        color: #fff;

        &-date,
        &-tag {
            position: relative;
            color: #fff;
        }
    }

    &-more {
        @include flex-col;
        width: 100%;
        border-radius: 15px;
        margin-bottom: 15px;
        padding: 5px;

        &-items {
            padding: 10px;
        }

        .more-title {
            font-size: 18px;
            padding: 10px 25px;
            font-family: 'jamalBold'
        }
    }
}

.news-item-tags {
    @include flex-row;
    padding: 5px 15px;

    .tags-link {
        padding: 5px;
        color: #000;

    }

    .color-tags {
        background-color: $main-color;
        color: #fff;
        font-size: 15px;
        border-radius: 10px;
        margin: 5px;
    }
}



/************* news page **************/