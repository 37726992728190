@keyframes slide-in-bck-center {
    0% {
        transform: translateZ(600px);
        opacity: 0;
    }

    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0 50%
    }
}

@keyframes morph {
    0% {
        border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
    }

    100% {
        border-radius: 40% 60%;
    }
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

@keyframes circle {
    0% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(450deg);
    }
}