/*-----------------------------
           mixin
-------------------------------*/
@mixin for-size($size) {

    @if $size ==sm {
        @media (max-width: 576px) {
            @content;
        }
    }

    @else if $size ==md {
        @media (max-width: 768px) {
            @content;
        }
    }

    @else if $size ==lg {
        @media (max-width: 992px) {
            @content;
        }
    }

    @else if $size ==xl {
        @media (max-width: 1200px) {
            @content;
        }
    }

    @else if $size ==xxl {
        @media (max-width: 1400px) {
            @content;
        }
    }
    
    @else if $size ==navbar {
        @media (max-width: 1400px) {
            @content;
        }
    }
}