/************* dead page**************/
.home-dead {
    &-content {
        clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0 100%);
        background-color: #f9fafb;
        padding-bottom: 25px;
    }

    &-title {
        width: 75%;
        font-family: 'jamalBold';
        font-size: 50px;
        padding: 15px;
        padding-top: 50px;
        background: linear-gradient(to left, #6366f1, #3b82f6, #10b981);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include for-size(md) {
            width: 100%;
            font-size: 25px;
        }
    }

    &-dis {
        padding: 5px 15px;
        font-size: 20px;


    }

    &-disc {
        padding: 5px 15px;
        font-size: 22px;
        color: $text-secondary;

        @include for-size(md) {
            font-size: 16px;
        }
    }

    &-card {
        @include flex-col;
        width: 100%;
        min-height: 550px;
        background: #fff;
        border-radius: 15px;
        box-shadow: $shadow;

        .card-title {
            font-size: 20px;
            padding: 20px;
            font-family: 'jamalBold';
        }

        .card-info {
            padding: 20px;

            &-text {
                font-size: 18px;
                line-height: 35px;
                color: $text-secondary;
                text-align: justify;

                @include for-size(md) {
                    font-size: 16px;
                }
            }

            &-title {
                font-size: 18px;
            }

            &-count {
                font-size: 16px;
            }

            &-names {
                font-size: 14px;
            }
        }

        .data-but {
            @include flex-row-center;
            align-content: flex-end;
            width: 50%;
            height: 50px;
            background-color: #68B984 !important;
            color: #fff !important;
            border-radius: 5px;
            transition: all 0.2s ease-in-out;
            border: none;
            font-size: 18px;

            &:hover {
                transform: scale(1.02);
            }
        }
    }
}

.card-man {
    @include flex-col-center;
    width: 100%;
    height: 250px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease-in-out;

    &:hover {
        transform: scale(1.02);
        z-index: 10;

        img {
            height: 100px;
            width: 100px;
            border-radius: 500px;
        }

        p {
            font-size: 12px;
        }
    }

    &-image {
        width: 100%;
        height: 200px;
        transition: all 0.5s ease-in-out;
        object-fit: cover;
    }

    &-info {
        @include flex-col-center;
        padding: 10px;

        &-title {
            font-size: 14px;
            color: #000 !important;
            text-align: center;
        }
    }
}

.dead-item {
    background-color: #fff;
    box-shadow: $shadow;
    margin-top: 75px;
    border-radius: 15px;
    position: relative;
    overflow: hidden !important;
    padding-bottom: 25px !important;

    @include for-size(sm) {
        margin-top: 30px;
    }

    &-bg {
        position: absolute;
        top: 0;
        background-color: $main-color;
        width: 100%;
        height: 350px;
        z-index: -1;
    }

    &-cover {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: top;

        @include for-size(md) {
            height: 300px;
        }

        @include for-size(sm) {
            height: 200px;
        }
    }

    &-header {
        @include flex-row;

        .header-avatar {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border: 3px solid #fff;
            border-radius: 15px;
            margin-top: -100px;

            @include for-size(md) {
                width: 150px;
                height: 150px;
                margin-top: -75px;
            }

            @include for-size(sm) {
                width: 100px;
                height: 100px;
                margin-top: -50px;
            }
        }

        .header-about {
            @include flex-col;
            padding: 15px;

            &-name {
                font-size: 18px;
                padding: 5px 0;
                margin: 0;
                font-family: 'jamalBold';

                @include for-size(sm) {
                    font-size: 15px;
                    padding: 2px 0;
                }
            }

            &-info {
                color: $text-secondary;



                span {
                    padding: 5px;
                    font-size: 14px;

                    @include for-size(sm) {
                        font-size: 15px;
                        padding: 2px 0;
                    }
                }
            }

            &-buts {
                @include flex-row;

                button {
                    @include main-button;
                    margin: 5px;
                    border-radius: 5px;
                    font-size: 14px;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }

                    @include for-size(sm) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &-card {
        @include flex-row;
        width: 100%;
        height: 60px;
        margin: 5px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: $shadow;

        &-icon {
            @include flex-col-center;
            height: 100%;
            margin: 0 5px;
            color: $text-secondary;
            font-size: 30px;
        }

        &-info {
            height: 100%;

            &-dis {
                font-size: 15px;
                margin: 5px 15px;
            }

            &-title {
                font-size: 14px;
                margin: 5px 15px;
                color: $main-color;
            }
        }
    }

    .text-more {
        overflow: auto;
        transition: all 0.5s ease-in-out;
        margin-bottom: 20px;
        &::-webkit-scrollbar{
            display: none
        }
    }

    .more-buts {
        @include flex-col;
        background-image: linear-gradient(to top, #fff, #ffffff7a);
        width: 100%;
        height: 50px;
        margin-top: -40px;
        z-index: 5;
        font-size: 12px;
        transition: all 0.5s ease-in-out;
    }

    .dead-image {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: $shadow;
        border-radius: 15px;
        overflow: hidden;


        .img-dead {
            width: 100% !important;
            height: 150px !important;
            border-radius: 15px !important;
            object-fit: cover !important;
            padding: 8px !important;
        }

        .img-content {
            width: 50%;
            height: 100%;
            position: relative;
            padding: 0;

        }
    }
}

/************* dead page**************/