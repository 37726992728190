/*-----------------------------
home page
-------------------------------*/
.home-container {
    @include flex-col;
    position: relative;

}

.home-header {
    @include flex-col-center;
    position: relative;
    height: 75vh;
    z-index: 2;
    background-color: var(--body-color);
}

.image-container {
    @include absolute-object;
    width: 100%;
    max-width: 100%;
    clip-path: ellipse(100% 100% at 50% 0%);
    height: 80vh
}

.home-header-image {
    width: 100%;
    max-width: 100%;
    height: 79vh;
    object-fit: cover;
    object-position: center;
    top: 0;
    clip-path: ellipse(100% 100% at 50% 0%);
}

.bg-image {
    @include absolute-object;
    width: 100%;
    height: 80vh;
    background-image: linear-gradient(to top, #23b5abd3, rgb(0, 0, 0, 0.3));
    z-index: 2;
}

.header-info {
    @include flex-col-center;
    z-index: 10;
    position: absolute;
    bottom: 10px;
}

.header-text {
    font-size: 25px;
    color: white;
    text-align: center;

    &-title {
        font-size: 30px;
        color: white;
        text-align: center;
    }
}

.header-svg {
    width: 100%;
    height: 25px;
    background-image: url('../../svg/dots.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.tap-scale {
    @include flex-row-center;
    padding: 10px;

    .tap-item {
        margin: 10px 25px;

        .scale {
            transform: scale(1.3)
        }

        img {
            width: 50px;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            img {
                transform: scale(1.4)
            }
        }
    }
}

.tap-content-scale {
    @include flex-col;
    position: relative;
    background-color: #fff;
    box-shadow: $shadow;
    border-radius: 15px;
    min-height: 350px;

    .image-content {
        @include flex-col-center;
    }

    .tap-item {
        @include flex-row-center;
        height: 100%;

        img {
            width: 250px;
            height: 100%;
        }

        .info-content {
            @include flex-col;
            justify-content: flex-start;
            height: 100%;
            min-height: 350px;

            .title {
                font-size: 30px;
                padding: 10px 20px;
            }

            .content-card {
                @include flex-row;
                margin-top: 10px;

                .card-info {
                    @include flex-col-center;
                    background-color: #eee;
                    width: 50px;
                    height: 50px;
                    border-radius: 15px;
                    margin: 5px;
                }

                .content {
                    font-size: 18px;
                    width: 95%;
                }
            }

        }
    }
}

.home-serves,
.home-news,
.home-tabs,
.home-quote {
    @include flex-col-center;
    position: relative;
    padding: 50px 0;
    z-index: 2;
    background-color: var(--body-color);
}

.home-serves {
    background-position: left;
    background-repeat: no-repeat
}

.home-serves-content,
.home-quote-content {
    @include flex-row-center;
}

.carousel-serves {
    width: 100%;
    height: 500px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(128, 128, 128, 0.212);
    background-position: left;
    transition: all 1s ease-in-out !important;

    @include for-size(sm) {
        height: 250px;
    }

    &-two {
        width: 100%;
        height: 500px;
        overflow: hidden;
        border-radius: 15px;
        box-shadow: 2px 2px 10px rgba(128, 128, 128, 0.212);
        transition: all 1s ease-in-out !important;

        @include for-size(sm) {
            height: 350px;
        }
    }

    img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;

        @include for-size(sm) {
            height: 250px;
        }
    }
}

.border-radius-15 {
    border-radius: 15px;
}

.carousel-serves-item {
    @include flex-col;
    width: 100%;
    height: 535.22px;
    background-color: white;
    border-radius: 15px;
    padding: 15px;
}

.logo-carousel-serves {
    width: 60px;
    margin: 15px 25px;
}

.carousel-serves-text {
    font-size: 14px;
    margin: 15px 20px;
    padding: 5px;
    color: #fff;
    background-color: #23b5ab86;
    text-align: center;
    width: fit-content;
    border-radius: 5px;
}

.carousel-serves-title {
    font-size: 20px;
    margin: 0 10px;
    padding: 0 10px;
    padding-top: 20px;
    color: #000;
}

.carousel-serves-date {
    font-size: 14px;
    margin: 0 10px;
    padding: 0 10px;
    color: $text-secondary;
    width: 100%;
}

.carousel-serves-dis {
    font-size: 18px;
    margin: 10px;
    padding: 15px 10px;
    color: $text-secondary;
    width: 100%;
    text-align: justify;
}

.home-gallery {
    @include flex-col;
    padding: 150px 0;
    padding-bottom: 250px;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
    overflow: hidden;
}

.home-gallery-2 {
    @include flex-col;
    padding: 50px 0 25px;
    position: relative;
    overflow: hidden;
}

.gallery-container {
    background-color: $main-color;
    border-radius: 15px;
    width: 100%;
    height: 100%;

    .swiper-container {
        width: 100%;
        padding: 10px;

        .image {
            width: 100%;
            height: 150px;
            object-fit: cover;
            border-radius: 10px;
            border: 3px solid transparent;
        }

        .br-color {
            border-color: white;
        }
    }

    .content-image {
        width: 100%;
        height: 100%;
        padding: 15px 5px;

        .image {
            width: 100%;
            height: 350px;
            object-fit: cover;
            border-radius: 15px;

            @include for-size(sm) {
                height: 250px;
            }
        }

        .content-info {
            color: white;
            padding: 40px 15px 10px 15px;
            height: 100%;

            .title {
                font-size: 20px;
            }

            .but-info {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 150px;
                font-size: 18px;
                padding: 22px 20px;
                color: #000 !important;
                background-color: #fff !important;
                border: none !important;
                z-index: 10;
                transition: all .2s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.slider-gallery {
    @include flex-row-center;
    position: relative;
    overflow: hidden;
    z-index: 50;
}

.gallery-image,
.bg-gallery,
.bg-images {
    @include absolute-object;
    object-fit: cover;
}

.gallery-image {
    z-index: -1;
}

.gallery-layout {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(100%/4.2));
    grid-auto-rows: 10px;
    justify-content: center;

    @include for-size('lg') {
        grid-template-columns: repeat(auto-fill, calc(100%/3.2));
    }

    @include for-size('md') {
        grid-template-columns: repeat(auto-fill, calc(100%/2));
    }
}

.bg-gallery {
    z-index: 2;
    background-image: linear-gradient(to bottom, $main-color, #00000080, $main-color);
}

.title-home {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 50px 0;
    color: #fff;
}



.but-daed {
    margin-top: -75px;

    @include for-size(md) {
        margin-top: 5px;
    }
}

.but-gallery {
    position: absolute;
    bottom: 100px;
}

.card-news-content {
    display: flex;
    flex-direction: unset;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    padding: 10px;

    @include for-size(lg) {
        flex-direction: row !important;
        flex-wrap: wrap !important;
        height: 100%;
    }

}

.card-news-item {
    flex: 1;
    height: 400px;
    background-color: white;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    margin-left: 5px;
    margin-bottom: 5px;
    background-repeat: no-repeat;
    transition: all .4s ease-in-out;

    @include for-size(lg) {
        flex: 1;
        min-width: 50%;
        width: 100%;
        height: 300px;
    }

    @include for-size(md) {
        flex: 2;
        min-width: 50%;
        width: 50%;
        height: 300px;
    }

    &:hover {
        flex: 2;

        .list-info {
            opacity: 1;
        }
    }
}

.news-title {
    @include flex-col;
    justify-content: flex-end;
    padding: 10px;
    height: 100%;

    & h4 {
        color: #fff;
        z-index: 50;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & p {
        color: #fff;
        z-index: 50;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .card-news-item:hover & p {
        opacity: 1;
    }
}

.card-news-more {
    z-index: 10;

}

.more-item {
    // filter: blur(25px);
    overflow: hidden;
    position: relative;
    filter: blur(0px);

    @include for-size(sm) {
        display: none;
    }

    &:hover {
        flex: 1;

        .more-news-title {
            opacity: 1;
        }
    }


    .more-news-title {
        opacity: 0;
        @include flex-col-center;
        @include absolute-object;
        color: #fff;
        z-index: 100;
        font-size: 20px;
        padding: 0;
        margin: 0;
        transition: all 0.3s ease-in-out;

        &:hover {
            backdrop-filter: blur(3px);
        }
    }
}

.lit-section {
    @include flex-col-center;
    background-color: var(--body-color);

    .author-carousel-card {
        height: 350px;
        box-shadow: $shadow;
        border-radius: 15px;
        position: relative;
        overflow: hidden;


        .image-item {
            object-fit: cover;
        }

        .name-item {
            @include absolute-object;
            @include flex-col;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            background-color: #00000070;
            z-index: 2;

            .title {
                color: white;
                font-size: 20px;
                padding: 5px 10px;
            }
        }
    }

    .slider-lit {
        height: 170px;
        width: 99%;
        box-shadow: $shadow;
        border-radius: 15px;
        position: relative;
        overflow: hidden;

        &-image {
            width: 99%;
            height: 100%;
            object-fit: cover;
        }

        &-name {
            @include absolute-object;
            @include flex-col;
            justify-content: flex-end;
            width: 99%;
            height: 100%;
            background-color: #00000070;
            z-index: 2;

            .title {
                color: white;
                font-size: 20px;
                padding: 0px 10px;
            }
        }
    }
}

.home-media {
    @include flex-col-center;
    background-color: var(--body-color);

    &-content {
        @include flex-row-between-center;
    }

    &-video,
    .home-media-sound {
        @include flex-col-center;
        margin: 10px 0;
        width: 50%;
    }
}

.video-home {
    @include flex-col-center;
    width: 100%;
    height: 600px;
    margin: 25px 0;
    border-radius: 15px;
    background-color: white;
    overflow: hidden;

    @include for-size(sm) {
        height: 100%;
    }

    &-items {
        @include flex-row-center;
        flex: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        flex-wrap: nowrap;
    }

    &-card {
        @include flex-col;
        width: 100%;
        background-color: white;
        border-radius: 15px;
        margin: 5px;
        position: relative;
        box-shadow: 2px 2px 10px rgba(128, 128, 128, 0.212);
    }

    &-player {
        @include flex-col;
        background-color: white;
        width: 100%;
        height: 70%;
        position: relative;
    }
}

.video-image-content {
    @include flex-col;
    justify-content: flex-end;
    height: 150px;
    position: relative;
    overflow: hidden;
}

.video-home-card-image {
    @include absolute-object;
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
}

.video-info-content {
    @include flex-col;
    position: relative;
    z-index: 10;
}

.bg-video {
    z-index: 2;
    background-image: linear-gradient(to top, #00000080, #00000030);
    border-radius: 15px;
    height: 150px;
}

.play-video {
    @include flex-row-center;
    z-index: 3;
    color: #000;
    background-color: white;
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    cursor: pointer;

    .video-home-card:hover & {
        color: #fff;
        background-color: $main-color;
    }
}

.video-home-card-title {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    width: 75%;
    padding: 0 5px;
    margin: 2px 5px;
}

.date-video-home {
    position: absolute;
    top: 0px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    color: #fff;
    z-index: 10;
}

.video-player {
    overflow: hidden;
    position: relative;

    &-viwe {
        width: 100%;
        background-color: #000;
    }

    &-poster {
        @include absolute-object;
        width: 75%;
        width: 100%;
        z-index: 10;
        border-radius: 0;
    }

    &-but {
        @extend .play-video;
        width: 50px;
        height: 50px;
        z-index: 12;
        font-size: 25px;

        &:hover {
            color: #fff;
            background-color: $main-color;
        }
    }
}

.sound-home {
    @include flex-col-center;
    width: 100%;
    min-height: 600px;
    flex-wrap: wrap;
    margin: 25px 0;
    overflow: hidden;

    &-content {
        @include flex-col-center;
        background-color: white;
        border-radius: 15px;
        padding: 10px;
        width: 100%;
        height: 600px;
    }

    &-player {
        display: flex;
        flex: 1;
        min-width: 100%;
        direction: ltr;
    }

    &-list {
        width: 100%;
        height: 510px;
        overflow-y: scroll;
        padding: 10px;
    }

    &-item {
        @include flex-row-between-center;
        width: 100%;
        margin: 5px 0px;
        border-radius: 10px;
        background-color: $third-color;
    }

    &-player .rhap_container {
        background-color: none !important;
        box-shadow: none !important;
    }

    &-title {
        font-size: 14px;
        padding: 15px 0 0 0;
    }
}

.forth-section {
    @include flex-col-center;
    position: relative;
    background-color: var(--body-color);

    .vr {
        &-content {
            @include flex-row();
            position: relative;
            padding: 0;
            margin: 0;
            margin-bottom: 50px;

            &::after {
                content: ' ';
                position: absolute;
                bottom: -30px;
                width: 100%;
                height: 150px;
                background-color: $main-color;
                clip-path: polygon(0 0, 100% 0, 100% 82%, 0 100%);
                z-index: 3;

                @include for-size(md) {
                    height: 100px;
                }
            }

            img {
                width: 100.1%;
                z-index: 4;
            }
        }

        &-point {
            @include absolute-object();
            @include flex-row();
            align-items: center;
            z-index: 10;

            .position {
                position: absolute;
                bottom: 35%;
                overflow: hidden;

                &-1 {
                    right: 5px;

                    @include for-size(xl) {
                        display: none;
                    }
                }

                &-2 {
                    right: 18%;

                    @include for-size(xl) {
                        display: none;
                    }
                }

                &-3 {
                    right: 18%*3.1;

                    @include for-size(lg) {
                        display: none;
                    }
                }

                &-4 {
                    right: 18%*4.7;

                    @include for-size(xl) {
                        display: none;
                    }
                }
            }

            .point {
                @include flex-row-center();
                width: 30px;
                height: 30px;
                border-radius: 50px;
                background-color: #979797d0;
                cursor: pointer;
                border: 1px solid #aaa;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: all 0.5s ease-in-out;

                &:hover {
                    width: 100%;
                    background-color: $main-color;

                    .point-style {
                        opacity: 1;
                        width: 100%;
                        white-space: nowrap;
                        transition: all 1s ease-in-out;
                    }

                    .point-dot {
                        opacity: 1;
                        transition: all 1s ease-in-out;
                    }
                }

                &-dot {
                    opacity: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 25px;
                    background-color: #fff;
                    margin: 0 5px;
                    transition: all 1s ease-in-out;
                }

                &-style {
                    opacity: 0;
                    width: 100%;
                    height: 30px;
                    color: #fff;
                    font-size: 10px;
                    padding: 5px 10px;
                    transition: all 0.5s ease-in-out;
                }
            }
        }
    }
}

.pray-section {
    @include flex-col-center;
    position: relative;
    background-color: var(--body-color);
    padding-top: 150px;

    &-info {
        @include for-size(xl) {
            @include flex-col-center;
        }

        .discraption {
            font-size: 18px;
            line-height: 30px;
            text-align: justify;
            padding: 10px 0;
        }

        p {
            &:first-child {
                font-size: 30px;
            }
        }

    }

}

.lab-item {
    .lab-inner {
        .lab-thumb {
            .img-grp {
                @include flex-col-center;
                position: relative;
                background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/pray_home.png');
                background-size: cover;
                width: 400px;
                height: 400px;
                margin: 20px auto;

                @include for-size(sm) {
                    width: 300px;
                    height: 300px;
                }

                .about-fg-img {
                    position: absolute;
                    z-index: 1;
                    right: 30px;
                    top: 80px;

                    @include for-size(sm) {
                        right: 20px;
                        top: 60px;
                    }

                    img {
                        width: 100%;

                    }
                }

                .about-fg-img-2 {
                    position: absolute;
                    z-index: 1;
                    right: 30px;
                    top: -10px;

                    @include for-size(sm) {
                        right: 20px;
                        top: -10px;
                    }

                    img {
                        width: 90%;
                    }
                }

                .about-fg-img-3 {
                    position: absolute;
                    z-index: 1;
                    right: 25px;
                    bottom: 20px;

                    @include for-size(sm) {
                        right: 15px;
                        top: 30px;
                    }

                    img {
                        width: 90%;
                    }
                }

                .about-circle {
                    display: block;
                    box-shadow: 0px 0px 20px 0px rgba(0, 255, 106, 0.3);
                    background: green;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    position: absolute;
                }

                .about-circle-2 {
                    display: block;
                    box-shadow: 0px 0px 20px 0px rgba(0, 255, 106, 0.3);
                    background: green;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    position: absolute;
                }

                .about-circle-wrapper {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0%;
                    left: 0%;
                    animation: circle 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;

                    .about-circle {
                        bottom: -15px;
                        left: 50%;
                        background-image: linear-gradient(to bottom, #8dc53f, #76c14e, #5ebd5b, #46b868, #29b373);
                    }

                    .about-circle-2 {
                        top: -16px;
                        right: 50%;
                        background-image: linear-gradient(to top, #8dc53f, #76c14e, #5ebd5b, #46b868, #29b373);
                    }
                }
            }
        }

        .lab-content {
            margin-bottom: 80px;
        }
    }
}

.six-section {
    @include flex-col-center;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    background-image: linear-gradient(to left, $linear);

    .nav-app {
        .nav-link {
            display: block !important;
            border: 1px solid rgba(255, 255, 255, .25) !important;
            padding: 30px 15px !important;
            margin: 10px;
            width: 150px;
            height: 125px;
            font-size: 18px;
            color: #fff;
            text-align: center;
            position: relative !important;
            border-radius: 15px !important;
            -webkit-border-radius: 15px !important;
            -moz-border-radius: 15px !important;

            @include for-size(sm) {
                width: 100px;
                height: 75px;
                font-size: 12px;
                margin: 5px;

            }

            &:hover {
                background: #fff !important;
                border-color: transparent !important;
                color: #000;
            }

            &.active {
                background: #fff !important;
                border-color: transparent !important;
                color: #000;

                &:after {
                    content: ' ';
                    opacity: 1;
                    background: #fff !important;
                }
            }

            &:after {
                content: "";
                width: 40px;
                height: 40px;
                position: absolute;
                z-index: 1;
                bottom: -8px;
                left: 50%;
                margin-left: -20px;
                opacity: 0;
                background: #ffffff00 !important;
                transform: rotate(-45deg);
                border-radius: 4px;

                @include for-size(sm) {
                    width: 20px;
                    height: 20px;
                    margin-left: -10px;
                    bottom: -10px;
                }
            }
        }
    }

    .image-apps {
        width: 75%;

    }

    .name-apps {
        font-size: 30px;
        color: #fff;
    }

    .dis-apps {
        font-size: 18px;
        color: #fff;
        line-height: 40px;
        text-align: justify;

    }
}

.third-section {
    @include flex-col-center;
    padding: 150px 0;
    position: relative;
    overflow: hidden;
    background-color: var(--body-color);

    &-carousel {
        @include flex-row-center;
        flex: 1;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);

        .carousel-control-prev {
            opacity: 0;

            &:hover {
                opacity: 1;
            }
        }

        .carousel-control-next {
            opacity: 0;

            &:hover {
                opacity: 1;
            }
        }
    }

    &-item {
        @include flex-row;
        flex: 1;
    }

    &-item-one {
        @include flex-col;
        height: 100%;
        justify-content: flex-start;
    }

    &-item-image {
        width: 100%;
        height: 500px;
        object-fit: cover;
        clip-path: polygon(0 0, 89% 0, 54% 100%, 0% 100%);
    }

    &-tabs {
        @include flex-row-center;
        width: 100%;
        margin-bottom: 25px;
        border: none;

        .nav-link {
            width: 400px;
            font-size: 16px;
            text-align: center;
            padding: 16px;
            font-weight: 600;
            border: 0px;
            margin: 10px 5px;
            border-radius: 16px;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            background-color: #f4f4f4;
            box-shadow: 0px 0px 0px rgb(0 0 0 / 20%);
            color: #8a8a8a;
        }

        .nav-link.active {
            box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        }
    }
}


.seven-section {
    @include flex-col-center;
    padding: 70px 0;
    position: relative;
    overflow: hidden;

    .seven-content {
        margin-top: -100px;

        @include for-size(sm) {
            margin-top: 0px;
        }
    }

    .image-dead-section {
        padding: 10px;
        height: 100%;
        width: 100%;
        z-index: 5;

        @include for-size(sm) {
            height: 80%;
            width: 80%;
        }
    }

    .dead-content {
        width: 100%;
        height: 100%;
        position: relative;

        div {

            p {
                display: inline-block;
                margin: 25px 0;
                width: 100%;
                font-size: 25px;
                white-space: nowrap;
                text-align: center;
            }
        }
    }

}

.quote-home {
    &-item {
        background-color: $main-color;
        border-radius: 15px;
        padding: 10px 55px;
        margin: 5px;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        height: 350px;
        position: relative;

        @include for-size(sm) {
            padding: 10px 60px;
            height: 275px;
        }
    }

    .quote-avatar {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -50px;
        @include flex-col-center;

        @include for-size(sm) {
            display: none;
        }

        .avatar {
            border: 7px solid $main-color;
        }
    }

    &-icon {
        font-size: 50px;
        color: #fff;
        margin: 0 5px;

        @include for-size(sm) {
            font-size: 35px;
        }
    }

    .icon-right {
        position: absolute;
        top: 5px;
        right: 15px;
    }

    .icon-left {
        position: absolute;
        bottom: 5px;
        left: 15px;
    }

    &-text-title {
        @include flex-col-between-start();
        height: 100%;
        width: 100%;
        padding: 75px 5px 50px;

        @include for-size(md) {
            padding: 50px 5px 25px;
        }

        @include for-size(sm) {
            padding: 35px 5px 15px;
        }
    }

    &-text {
        color: #fff;
        font-size: 15px;
        text-align: justify;
        line-height: 30px;

        @include for-size(md) {
            font-size: 13px;
            line-height: 25px;
        }

        @include for-size(sm) {
            font-size: 12px;
            line-height: 20px;
        }

    }

    &-title {
        color: #fff;
        font-size: 14px;
        text-align: justify;

        @include for-size(md) {
            font-size: 12px;
        }

        @include for-size(sm) {
            font-size: 11px;
        }
    }
}

.app-image {
    width: 150px;
}