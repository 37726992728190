/************* oasis dinia page**************/
.oasis-dinia {
    &-page {
        margin-top: 20px;

        .header-home {
            @include flex-col-center;
            background-color: #10b981;
            border-radius: 15px;
            box-shadow: $shadow;
            padding: 15px;
            position: relative;

            .image-content {
                @include flex-col-center;
                width: 400px;
                align-self: center;
                background-image: url('../../images/cover_image.png');
                background-size: 250px;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                padding: 50px 0;

                .image-flower {
                    width: 75px;
                    height: 75px;
                    position: absolute;
                    z-index: 5;
                    left: 60px;
                    top: 75px;
                }

                .image-flower-2 {
                    width: 120px;
                    height: 100px;
                    position: absolute;
                    z-index: 5;
                    right: 30px;
                    bottom: 25px;
                    transform: rotate(270deg);

                }

                .cover-image {
                    width: 400px;
                    height: 100%;
                    object-position: top;
                    object-fit: contain;
                    -webkit-mask-image: url('../../images/cover_image.png');
                    -webkit-mask-size: contain;
                    -webkit-mask-position: center center;
                    -webkit-mask-repeat: no-repeat;
                }
            }


            .card-headers {
                @include flex-col;
                width: 100%;
                height: 100%;
                border-radius: 15px;
                position: relative;

                .name-header {
                    color: #fff;
                    padding: 5px 15px;
                    font-size: 20px;

                    @include for-size(sm) {
                        font-size: 18px;
                    }
                }

                .info-header {
                    font-family: 'Uthman_Taha' !important;
                    font-size: 22px;
                    font-weight: 500;
                    padding: 0 10px;
                    color: #fff;
                    text-align: justify;

                    @include for-size(sm) {
                        font-size: 20px;
                    }
                }

                .link-header {
                    font-size: 18px;
                    border-radius: 10px;
                    border: 1px solid #fff;
                    color: #fff;
                    padding: 5px 10px;
                    width: 100px;
                    text-align: center;

                    @include for-size(md) {
                        width: 150px;
                    }

                    @include for-size(sm) {
                        width: 100%;
                        background-color: #fff;
                        color: $main-color;
                    }

                    &:hover {
                        background-color: #fff;
                        color: $main-color;
                    }
                }
            }
        }

        .side-list {
            @include flex-col-center;
            background-color: #fff;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: $shadow;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                right: -12px;
                height: 100%;
                width: 50px;
                background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/islm.png');
                background-repeat: no-repeat;
                background-position: center;
                transform: rotate(90deg);

            }

            &::after {
                content: '';
                position: absolute;
                left: -12px;
                height: 100%;
                width: 50px;
                background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/islm.png');
                background-repeat: no-repeat;
                background-position: center;
                transform: rotate(270deg);

            }

            .links-list {
                @include flex-col-center;
                padding: 15px;
                width: 90%;

                a {
                    padding: 10px;
                    color: #000;
                    font-size: 14px
                }
            }
        }

        .card-salah {
            @include flex-col;
            background-color: #fff;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            padding: 10px;
            overflow: hidden;
            box-shadow: $shadow;
            position: relative;

            .header {
                @include flex-row;
                align-items: center;

                .date {
                    @include flex-col;
                    padding: 10px;

                    p {
                        &:first-child {
                            font-size: 25px;
                            color: #000;
                            padding: 0;
                            margin: 0;
                        }

                        &:last-child {
                            font-size: 16px;
                            color: #000;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }

            }

            .time-salah {
                display: flex;
                flex-direction: unset;
                width: 100%;
                margin: 10px 0;

                .time-item {
                    @include flex-col-center;
                    flex: 1;
                    background-color: #10b981;
                    margin: 5px;
                    border-radius: 5px;

                    p {
                        margin: 0;
                        padding: 5px;
                        color: #fff;
                    }
                }
            }
        }

        .card-header {
            @include flex-col-center;
            background-color: #fff;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: $shadow;

            .title {
                @include flex-col-center;
                background-image: linear-gradient(to left, $linear);
                width: 100%;

                .name {
                    font-size: 30px;
                    text-align: center;
                    padding: 25px 10px;
                    color: #fff;
                }
            }

            .info {
                padding: 10px;

                .title-info {
                    font-size: 18px;
                    padding: 10px;
                    text-align: justify;
                }

            }

            .line {
                width: 50%;
                height: 1px;
                background-color: $text-secondary;
            }

            .date-card {
                @include flex-col-center;
                padding: 15px;
            }
        }

        .card-text {
            @include flex-col-center;
            background-color: #fff;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            box-shadow: $shadow;
            margin: 15px 0 0 5px;

            .name-text {
                background-color: #12b981;
                color: #fff;
                padding: 5px 15px;
                position: relative;
                top: -15px;
                border-radius: 15px;

                @include for-size(sm) {
                    font-size: 14px;
                }
            }

            .info-text {
                font-family: 'Uthman_Taha' !important;
                font-size: 22px;
                font-weight: 500;
                padding: 0 5px;
                text-align: center;

                @include for-size(sm) {
                    font-size: 16px;
                }
            }
        }

        .cover-wills {
            position: relative;
            border-radius: 15px;
            overflow: hidden;
            z-index: 2;

            &::after {
                content: '';
                @include absolute-object();
                background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/cover.jpg');
                opacity: 0.1;
                z-index: -1;
            }
        }

        .wills {
            @include flex-col-center;
            background-color: #10b981;
            border-radius: 15px;
            box-shadow: $shadow;
            padding: 15px;
            position: relative;

            .image-content {
                @include flex-col-center;
                width: 225px;
                align-self: center;
                background-image: url('../../images/cover_image.png');
                background-size: 210px 95%;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;

                .image-flower {
                    width: 75px;
                    height: 75px;
                    position: absolute;
                    z-index: 5;
                    left: -10px;
                }

                .cover-image {
                    width: 200px;
                    height: 90%;
                    object-position: top;
                    -webkit-mask-image: url('../../images/cover_image.png');
                    -webkit-mask-size: contain;
                    -webkit-mask-position: center center;
                    -webkit-mask-repeat: no-repeat;
                }
            }


            .card-wills {
                @include flex-col;
                width: 100%;
                height: 100%;
                border-radius: 15px;
                position: relative;
                padding: 0 15px;

                @include for-size(sm) {
                    padding: 0 5px;
                }

                .name-wills {
                    color: #fff;
                    padding: 5px 15px;
                    font-size: 20px;

                    @include for-size(sm) {
                        font-size: 18px;
                    }
                }

                .info-wills {
                    font-family: 'Uthman_Taha' !important;
                    font-size: 22px;
                    font-weight: 500;
                    padding: 0 10px;
                    color: #fff;
                    text-align: justify;

                    @include for-size(sm) {
                        font-size: 20px;
                    }
                }
            }
        }

        .pray-time {
            @include flex-col-center;
            background-color: #10b981;
            border-radius: 15px;
            box-shadow: $shadow;
            padding: 15px;

            .image-content {
                @include flex-col-center;
                align-self: center;
                background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/icons/cover_image_2.png');
                background-size: 95%;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;

                .image-flower {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    z-index: 5;
                    top: 5px;
                    left: 0px;
                }

                .image-flower-2 {
                    width: 125px;
                    height: 100px;
                    position: absolute;
                    z-index: 5;
                    bottom: -5px;
                    right: 0px;
                }

                .cover-image {
                    width: 90%;
                    height: 90%;
                    object-position: top;
                    -webkit-mask-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/icons/cover_image_2.png');
                    -webkit-mask-size: contain;
                    -webkit-mask-position: center center;
                    -webkit-mask-repeat: no-repeat;
                }
            }

            .pray-times {
                display: grid;
                grid-template-columns: 50% 50%;

                p {
                    color: #fff;
                    font-size: 18px
                }

            }

            .card-pray {
                @include flex-col;
                width: 100%;
                height: 100%;
                border-radius: 15px;
                position: relative;

                .name-pray {
                    color: #fff;
                    padding: 5px 0px;
                    font-size: 25px;
                }

                .info-pray {
                    font-family: 'Uthman_Taha' !important;
                    font-size: 22px;
                    font-weight: 500;
                    padding: 0 10px;
                    color: #fff;
                    text-align: justify;
                }
            }
        }

        .list-main {
            display: flex;
            flex-direction: unset;
            height: 500px;
            width: 100%;

            @include for-size(md) {
                flex-direction: row !important;
                flex-wrap: wrap !important;
                height: 100%;
            }

            .list {
                &-1 {
                    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/quran_bg.png');
                }

                &-2 {
                    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/zyarat_bg.png');
                }

                &-3 {
                    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/anwar_bg.png');
                }

                &-4 {
                    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/isifta_bg.png');
                }
            }

            .list-item {
                @include flex-col-center;
                flex: 1;
                background-size: cover;
                background-position: center;
                color: #fff;
                margin-left: 5px;
                margin-bottom: 5px;
                background-repeat: no-repeat;
                transition: all .4s ease-in-out;

                @include for-size(md) {
                    flex: 2;
                    min-width: 50%;
                    width: 50%;
                    height: 300px;
                }

                &:hover {
                    flex: 4;

                    .list-info {
                        opacity: 1;
                    }
                }

                .list-info {
                    @include flex-col-center;
                    width: 100%;
                    height: 100%;
                    background-color: #00000060;
                    opacity: 0;
                    transition: opacity .4s ease-in-out;

                    @include for-size(md) {
                        opacity: 1;
                    }

                    .list-title {
                        font-size: 30px;
                        text-align: center;

                        @include for-size(sm) {
                            font-size: 20px;
                        }
                    }

                    .list-but {
                        @include main-button;
                    }
                }
            }
        }

        .pray-list {
            @include flex-col;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 15px;
            box-shadow: $shadow;
            position: relative;

            .name-list {
                font-size: 22px;
                padding: 10px;
                margin: 15px;
                border-bottom: 1px solid $third-color;
            }

            .pray-item {
                overflow: hidden;

                &:last-child:nth-child(odd) {
                    width: 100%
                }

                .list-item {
                    @extend .pray-list;
                    justify-content: center;
                    box-shadow: none;
                    border: 1px solid $third-color;
                    color: #000;
                    transition: all 0.2s ease-in;

                    &::after {
                        content: '';
                        @include absolute-object;
                        background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/cover.jpg');
                        background-size: cover;
                        opacity: 0.1;
                        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
                    }

                    &:hover {
                        background-color: $main-color;
                        color: #fff;
                    }

                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 18px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
        }

        .pray-content {
            @include flex-col;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 15px;
            box-shadow: $shadow;
            position: relative;

            .info-content {
                @include flex-row-between-start;
                padding: 10px;
                margin: 15px;
                border-bottom: 1px solid $third-color;
            }

            .text-content {
                padding: 15px;

                .text {
                    font-size: 25px;
                    line-height: 60px;
                    font-family: 'Uthman_Taha';
                    text-align: justify;
                }
            }

            .pray-header {
                @include flex-col-center();
                padding: 50px 10px;
                position: relative;
                overflow: hidden;
                background-color: $main-color;
                width: 100%;
                height: 100%;
                border-radius: 5px;

                p {
                    font-size: 25px;
                    color: #fff;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                }

                &::after {
                    content: '';
                    @include absolute-object;
                    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/cover.jpg');
                    background-size: cover;
                    opacity: 0.15;
                    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
                }
            }

            .more-item {
                padding: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                a {
                    color: #000;
                    padding: 5px;
                }
            }
        }

        .questions-author {
            @include flex-col-center;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 15px;
            box-shadow: $shadow;
            color: #000;
            position: relative;
            text-align: center !important;
            height: 350px;

            .image-content {
                @include flex-col-center;
                height: 260px;
                position: relative;

                .cover-author {
                    @include absolute-object;
                    height: 255px;
                    width: 250px;
                }

                .img-author {
                    height: 245px;
                    object-fit: cover;
                    width: 250px;
                    object-position: top;
                    -webkit-mask-image: url('../../images/cover_image.png');
                    -webkit-mask-size: contain;
                    -webkit-mask-position: center center;
                    -webkit-mask-repeat: no-repeat;
                }

                .name-author {
                    width: 100%;
                    padding: 10px;
                    margin: 5px;
                    text-align: center !important;
                }
            }

        }

        .questions-content {
            @include flex-col;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 15px;
            box-shadow: $shadow;
            color: #000;
            position: relative;

            .title-questions {
                font-size: 22px;
                border-bottom: 1px solid $third-color;
                padding: 10px;
                margin: 5px;
            }

            .questions-item {
                @include flex-col;
                padding: 15px;

                .item-header {
                    margin: 5px;

                    p {
                        background-color: $main-color;
                        color: #fff;
                        width: 100px;
                        padding: 5px;
                        border-radius: 50px 0 0 50px;
                        font-size: 16px;
                        border-right: 5px solid $second-color;
                    }
                }

                .questions-answer {
                    @include flex-col;

                    .questions-text {
                        margin: 5px;

                        span {
                            padding: 5px;
                            line-height: 50px;

                            &:first-child {
                                color: #10b981;
                                border-radius: 5px;
                            }
                        }
                    }

                    .answer-text {
                        margin: 5px;

                        span {
                            padding: 5px;
                            line-height: 50px;

                            &:first-child {
                                color: #00BED4;
                                border-radius: 5px;
                            }
                        }
                    }
                }

                .questions-footer {
                    font-size: 14px;
                    border-top: 1px solid $third-color;
                    padding: 10px;
                    margin: 5px;
                }
            }
        }

        .surah-content {
            @include flex-col;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 15px;
            box-shadow: $shadow;
            position: relative;

            .info-content {
                @include flex-row-between-start;
                padding: 10px;
                margin: 15px;
                border-bottom: 1px solid $third-color;

                p {
                    font-size: 20px;
                }
            }

            .text-content {
                @include flex-row;
                align-items: center;
                padding: 15px;
                border-bottom: 1px solid $third-color;
                position: relative;

                &:last-child {
                    border: none;
                }

                .text {
                    font-size: 25px;
                    line-height: 60px;
                    font-family: 'Uthman_Taha';
                    text-align: justify;
                    width: 95%;

                    @include for-size(sm) {
                        font-size: 20px;
                        padding-top: 25px;
                    }
                }

                .ayah-icon {
                    @include flex-col-center;
                    position: absolute;
                    top: 2px;
                    left: 5px;
                    width: 35px;
                    height: 50px;
                    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/icons/ayah.png');
                    background-position: center;
                    background-size: 30px 40px;
                    background-repeat: no-repeat;

                    p {
                        margin: 0;
                        padding: 0;
                        font-family: 'Uthman_Taha';
                    }
                }
            }
        }
    }
}

/************* oasis dinia page**************/