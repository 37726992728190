/*-----------------------------
           variables
-------------------------------*/
$main-color: #23b5ab !default;
$second-color: #19978f !default;
$third-color: #EAF6F6 !default;
$color_1: #9ca3af;
$color-4: #f8f8f8;
$color-5: rgb(250, 250, 250);
$shadow: 0px 0px 10px rgba(128, 128, 128, 0.212);
$shadow-2: 0px 0px 10px #23b5ab6b;
$color-6: #000;
$color-7: #fff;
$color-8: #868a95;
$color-9: #23b5ab;
$color-10: #838383;
$color-11: #47a197;
$background-color-6: white;
$background-color-7: #fff;
$background-color-8: #f1f2f6;
$background-color-9: rgba(255, 255, 255, 0);
$linear: $main-color,
#5aba8a;
$text-secondary: #6c757d;