.soon-item {
    position: relative;
    overflow: hidden;

    &::after {
        content: ' ';
        @include absolute-object;
        width: 100%;
        height: 100%;
        background-color: #eee;
        background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/lines.png');
        opacity: 0.8;
    }
}