$sizes: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,23,24,25,30,35,40,45,50;
$lines: 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 100;

@each $size in $sizes {

    .fs-#{$size} {
        font-size: #{$size}px !important;
    }
}

@each $lines in $lines {

    .line-#{$lines} {
        line-height: #{$lines}px !important;
    }
}