/*-----------------------------
slider home
-------------------------------*/
.carousel-home {
    position: relative;
    width: 23rem;
    height: 23rem;
    perspective: 500px;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
}

.card-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.2)) translateZ(calc(var(--abs-offset) * -30rem)) translateX(calc(var(--direction) * -5rem));
    filter: blur(calc(var(--abs-offset) * 0.5rem));
    transition: all 0.5s ease-in-out;
}

.card-gallery {
    width: 100%;
    height: 100%;
    padding: 2rem;
    border-radius: 1rem;
    color: $color_1;
    text-align: justify;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    a {
        position: absolute;
        font-size: 18px;
        margin: 0 0 0.7em;
        color: $color-7;
        bottom: 15px;
        transition: all 0.3s ease-out;
        opacity: var(--active);
        z-index: 4;
        transition: all 0.5s ease-in-out;
    }

    p {
        transition: all 0.3s ease-out;
        opacity: var(--active);
        z-index: 4;
        transition: all 0.5s ease-in-out;
    }
}

.card-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: all 0.5s ease-in-out;

}

.card-image-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #000000e0, #00000030);
    z-index: 2;
}

.nav-control {
    color: $color-7;
    font-size: 5rem;
    position: absolute;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;


}

.nav-control.left {
    transform: translateX(-100%) translateY(-50%);

    @include for-size(md) {
        transform: translateX(-75%) translateY(-50%);
    }

    @include for-size(sm) {
        transform: translateX(-45%) translateY(-50%);
    }
}

.nav-control.right {
    right: 0;
    transform: translateX(100%) translateY(-50%);

    @include for-size(md) {
        transform: translateX(75%) translateY(-50%);
    }

    @include for-size(sm) {
        transform: translateX(45%) translateY(-50%);
    }
}