.share {
    @include flex-col-center;

    .share-buts {
        @include flex-row-center;
        flex-wrap: nowrap;
        overflow-x: auto;
        position: relative;

        button {
            margin: 5px;
        }
    }
}

.ant-popover-title {
    font-family: 'jamalRegular' !important;
}