.btn-close {
    position: absolute;
    left: 5px;
}

button {
    border: none !important;
}

.modal-but {
    @include main-button;
    width: 25%;
}

label {
    font-size: 14px;
}

a {
    text-decoration: none !important;
    cursor: pointer !important;
}

.nav-link {
    color: #000;

    &:hover {
        color: #000;
    }
}
.accordion-button {
    font-family: 'jamalRegular';
}
.accordion-button {
    @include flex-row-between-center;
    text-align: right !important;
    color: #000 !important;

    &:focus {
        box-shadow: none;
    }

    i {
        color: $text-secondary;
    }

    &::after {
        margin-left: 5px !important;
        background-image: var(--bs-accordion-btn-icon) !important;
    }

    &:not(.collapsed) {
        background-color: #fff;
        box-shadow: none;
    }
}

.label {
    font-size: 12px;
    margin: 2px;
}

.icon-input {
    color: $text-secondary;
}

.header-link {
    @include flex-row;

    &-item {
        background-color: #fff;
        color: $main-color;
        padding: 5px 10px;
        margin: 5px;
        border-radius: 10px;
        font-size: 12px;
        box-shadow: $shadow;

        &:hover {
            color: $main-color;
        }

        &:last-child {
            background-color: $main-color;
            color: #fff;
        }
    }
}


/* ---- reset ---- */

canvas {
    display: block;
    vertical-align: bottom;
}

/* ---- particles.js container ---- */


h2 {
    font-family: 'jamalBold'
}

.fr0 {
    fill: #fff
}

.st0,
.sr0 {
    fill: #23b5ab
}