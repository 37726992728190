   .card-author {
       @include flex-col-between-center;
       border-radius: 15px;
       padding: 15px 0;
       width: 100%;
       height: 330px;
       background-color: $color-5;
       transition: all 0.5s ease-in-out;

       &:hover {
           box-shadow: $shadow;
       }

       &-content {
           @include flex-col-between-center;
           border-radius: 15px;
           padding: 15px;
           height: 100%;
           width: 100%;

           .avatar {
               box-shadow: $shadow-2;
               margin: 5px;
               border: 3px solid #fff;
           }

           .avatar-title {
               background-color: $main-color;
               color: #fff;
               font-size: 12px;
               padding: 0px 15px;
               margin-top: -15px;
               border-radius: 15px;
               z-index: 3;
           }

           .title-1,
           .title-2 {
               font-size: 14px;
               margin: 0 5px;
               padding: 0;
               color: #000;
           }

           .title-2 {
               font-size: 12px;
           }

           .info-count {
               @include flex-row;
               justify-content: space-evenly;
               width: 100%;
               padding: 10px 0;

               p {
                   @include flex-col-center;

                   span {
                       &:first-child {
                           font-size: 14px;
                           padding: 2px;
                           color: #000;
                       }

                       &:last-child {
                           font-size: 13px;
                           color: $text-secondary;
                       }
                   }
               }
           }
       }

       .but-flow {
           @include flex-row-center;
           @include main-button;
           padding: 5px 15px;
           margin: 5px 10px;
           border-radius: 15px;
       }
   }


   .card-catogary {
       position: relative;
       width: 100%;
       height: 300px;
       overflow: hidden;
       border-radius: 15px;
       cursor: pointer;

       &:hover {
           box-shadow: $shadow;

           .img-catogary {
               transform: scale(1.2)
           }
       }

       .img-catogary {
           @include absolute-object;
           z-index: -1;
           width: 100%;
           height: 100%;
           object-fit: cover;
           object-position: center center !important;
           transition: all 1s ease-in-out
       }

       .catogary-info {
           @include flex-col;
           justify-content: flex-end;
           align-items: center;
           width: 100%;
           height: 100%;
           z-index: 2;
           background-image: linear-gradient(to top, $main-color, #5aba8a5d);

           .title-catogary {
               font-size: 18px;
               color: #fff;
               padding: 10px 5px
           }

           .but-catogary {
               font-size: 18px;
               color: #fff;
               border: 1px solid #fff;
               padding: 5px 15px;
               border-radius: 5px;
               cursor: pointer;
           }
       }
   }


   .news-card {
       @include flex-row;
       align-items: center;
       width: 100%;
       height: 250px;
       max-height: 250px;
       min-height: 150px;
       background-color: #fff;
       border-radius: 15px;
       padding: 15px;
       position: relative;
       overflow: hidden;

       @include for-size(lg) {
           height: 200px;
       }

       @include for-size(md) {
           height: 125px;
           padding: 5px;
       }

       &-image {
           width: 200px;
           height: 200px;
           max-height: 200px;
           min-height: 125px;
           border-radius: 15px;
           object-fit: cover;

           @include for-size(lg) {
               width: 175px;
               height: 175px;
           }

           @include for-size(md) {
               width: 120px;
               height: 120px;
           }
       }

       &-info {
           @include flex-col;
           justify-self: flex-start;
           align-items: flex-start;
           padding: 5px 15px;
           overflow: hidden;
           height: 100%;
           position: relative;

           &-title {
               font-size: 18px;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               color: #000;
               width: 98%;

               @include for-size(lg) {
                   font-size: 16px;
               }

               @include for-size(md) {
                   font-size: 14px;
               }

               @include for-size(sm) {
                   font-size: 12px;
               }
           }

           &-content {
               display: inline;
           }

           &-tag {
               color: $text-secondary;
               line-height: 2;
               font-size: 12px;

               @include for-size(md) {
                   font-size: 10px;
               }

               @include for-size(sm) {
                   font-size: 9px;
               }
           }

           &-date {
               display: flex;
               align-items: center;
               color: $text-secondary;
               font-size: 12px;
               margin: 0;

               @include for-size(sm) {
                   font-size: 10px;
               }
           }

           &-summary {
               color: $text-secondary;
               font-size: 14px;
               margin: 8px 0px;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               overflow: hidden;
               -webkit-line-clamp: 4;

               @include for-size(lg) {
                   font-size: 12px;
                   -webkit-line-clamp: 3;
               }

               @include for-size(md) {
                   -webkit-line-clamp: 2;
               }

               @include for-size(sm) {
                   font-size: 10px;
               }
           }
       }

       .share-but {
           position: absolute;
           left: 10px;
           top: 10px;

           .icon {
               font-size: 22px;

               @include for-size(lg) {
                   font-size: 16px;
               }

           }
       }
   }


   .more-card {
       @include flex-row;
       align-items: center;
       width: 100%;
       height: 150px;
       background-color: #fff;
       border-radius: 15px;
       padding: 5px;
       margin: 5px 0;

       @include for-size(md) {
           height: 135px;
           padding: 5px;
       }

       &-image {
           width: 125px;
           height: 125px;
           border-radius: 15px;
           object-fit: cover;

           @include for-size(md) {
               width: 120px;
               height: 120px;
           }
       }

       &-info {
           @include flex-col;
           justify-self: flex-start;
           align-items: flex-start;
           padding: 5px;
           overflow: hidden;
           text-overflow: ellipsis;
           height: 100%;
           position: relative;

           &-title {
               font-size: 16px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
               width: 100%;
               margin: 5px;
               color: #000;

               @include for-size(md) {
                   font-size: 14px;
               }

               @include for-size(sm) {
                   font-size: 12px;
               }
           }

           &-content {
               display: inline;
           }

           &-tag {
               color: $text-secondary;
               font-size: 12px;
               margin: 2px;

               @include for-size(md) {
                   font-size: 10px;
               }

               @include for-size(sm) {
                   font-size: 9px;
               }
           }

           &-date {
               display: flex;
               align-items: center;
               color: $text-secondary;
               font-size: 12px;
               margin: 0;

               @include for-size(sm) {
                   font-size: 10px;
               }
           }

           &-summary {
               color: $text-secondary;
               font-size: 14px;
               margin: 8px 0px;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               overflow: hidden;
               -webkit-line-clamp: 1;

               @include for-size(lg) {
                   font-size: 12px;

               }

               @include for-size(sm) {
                   font-size: 10px;
               }

               a {
                   color: #000 !important;

                   @include for-size('phone') {
                       display: none;
                   }

                   &:hover {
                       color: #8D9093 !important;
                   }
               }
           }
       }
   }