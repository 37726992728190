/************* literature page**************/
.literature-page {
    &-search {
        position: absolute;
        top: 10px;
        z-index: 50;

        .search-item {
            @include flex-col-center;
            padding: 5px;
            background-color: #fff;

            .neme {
                font-size: 12px;
                color: #000;
            }
        }

        &-content {
            @include flex-col;
            flex-wrap: wrap;
            position: absolute;
            background-color: #fff;
            top: 42px;
            width: 100%;
            z-index: 20;
            padding: 10px;
            border-radius: 15px;
            box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
            color: #000;
        }
    }

    .section-1 {
        padding-top: 50px !important;
    }

    .section-1,
    .section-3 {
        background-color: #ebeff5;
    }

    .section-4 {
        background-color: #ebeff5;
    }

    .section-2 {
        background-color: #fafbfd;
    }

    .section-1,
    .section-2,
    .section-3 {
        margin: 0;
        padding: 0;

        .title-1 {
            font-size: 22px;
            padding: 25px 0;
        }

        .title-2 {
            font-size: 18px;
            padding: 25px 0;
            text-align: justify
        }

        .img-section {
            width: 100%;
            height: 500px;
            object-fit: contain;
            object-position: bottom;

            @include for-size(sm) {
                height: 300px;
            }
        }
    }

    .section-1 {
        @include for-size(xl) {
            padding-top: 80px;
        }
    }
    
    &-cover {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: top;

        @include for-size(sm) {
            height: 200px;
        }
    }

    &-header {
        @include flex-row;
        background-color: #fff;

        .header-avatar {
            object-fit: cover;
            border: 3px solid #fff;
            margin-top: -75px;
            margin-right: 120px;

            @include for-size(md) {
                margin-top: -60px;
                margin-right: 50px;
            }

            @include for-size(sm) {
                margin-top: -50px;
                margin-right: 10px;
            }
        }

        .header-about {
            @include flex-row;
            justify-content: space-between;
            padding: 15px;
            flex: 1;

            &-name {
                font-size: 18px;
                padding: 5px 0;
                margin: 0;

                @include for-size(sm) {
                    font-size: 14px;
                }
            }

            &-info {
                color: $text-secondary;

                span {
                    padding: 5px;
                    font-size: 14px;
                }
            }

            &-buts {
                @include flex-row;

                button {
                    @include main-button;
                    margin: 5px;
                    border-radius: 5px;
                    font-size: 14px;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .poem-card {
        @include flex-col;
        background-color: #fff;
        padding: 0 15px;
        border-radius: 15px;
        box-shadow: $shadow;
        color: #000;

        p {
            color: #000;
        }

        .shadow {
            box-shadow: $shadow;
        }

        .title-content {
            @include flex-col;
            border-bottom: 1px solid $color-4;
            margin: 10px;

            .title-1 {
                font-size: 18px;
                color: #000;
            }

            .title-2 {
                font-size: 14px;
                color: $text-secondary;
            }
        }

        .footer-content {
            @include flex-row-between-center;
            margin: 0 10px;
            padding-top: 5px;
            height: 100%;
            border-top: 1px solid $color-4;

            .footer-count {
                @include flex-row-center();

                p {
                    color: $text-secondary;
                    padding: 10px;
                }
            }

            .footer-but {
                font-size: 18px;
                color: $main-color;
                cursor: pointer;
            }
        }
    }

    .more-poem {
        background-color: #fff;
        padding: 0 15px;
        border-radius: 15px;

        .title {
            padding: 10px;
        }

        .poem-item {

            padding: 10px 5px;
            font-size: 15px;
            color: #000;

            a {
                color: #000 !important;
            }
        }
    }

    .pome-content {
        @include flex-col;
        background-color: #fff;
        padding: 15px;
        border-radius: 15px;
        box-shadow: $shadow;

        .pome-header {
            @include flex-row-between-center;
            border-bottom: 1px solid $color-4;

            .title {
                font-size: 18px;
                padding: 10px
            }

            .buts {
                @include flex-row;

                button {
                    padding: 0 15px;
                    margin: 0;
                }
            }
        }

        .pome {
            padding: 10px;
            min-height: 600px;

            .text {
                font-size: 22px;
                padding: 10px 0;
                text-align: center;
            }
        }
    }

    .catogary-header {
        @include flex-col-center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: $shadow;
        overflow: hidden;

        .image-header {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }

        .title {
            font-size: 18px;
            padding: 15px 0;
        }

        .count {
            color: $text-secondary;
            font-size: 14px;
        }
    }

    &-tabs {
        border: none;

        @include for-size(sm) {
            margin: 5px 0px !important;
            justify-content: center !important;
            padding: 0;
            padding-bottom: 20px;
        }

        .nav-item .nav-link {
            margin: 10px;
            cursor: pointer !important;
            border: 1px solid transparent !important;
            border-radius: 100px !important;
            white-space: nowrap !important;
            font-size: 13px !important;
            color: #000 !important;
            background-color: rgb(211, 214, 224) !important;
            overflow: hidden;

            @include for-size(sm) {
                margin: 2px;
                font-size: 10px !important;
            }
        }

        .nav-item .nav-link.active {
            background-color: $main-color !important;
            color: #fff !important;
        }
    }

    &-info {
        border-bottom: 1px solid #eee;
        padding: 10px;

        &-links {
            font-size: 14px;
            color: $text-secondary;
        }
    }
}

/************* literature page**************/