/************* gallery page**************/
.gallery-images {
    background-color: $main-color;
    position: relative;
    border-radius: 15px;
    margin: 8px;
    padding: 0;
    overflow: hidden;

    &:hover {
        .gallery-images-cover {
            opacity: 1;
        }
    }

    &-cover {
        @include flex-col-between-start;
        @include absolute-object;
        height: 100%;
        width: 100%;
        z-index: 3;
        background-color: #00000080;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    &-card {
        @include flex-col-between-start;
        @include absolute-object;
        height: 100%;
        width: 100%;
        z-index: 3;
        background-color: #00000080;
        border-radius: 15px;
    }

    &-but {
        @include flex-col-center;
        width: 40px;
        border-radius: 50px;
        background-color: $main-color;
        color: #fff !important;
        margin: 5px 10px;

        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }

    &-more {
        @include flex-row;
        width: 100%;

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    &-dropdown {
        @include flex-col-center;
        width: 25px;
        font-size: 12px;
        border-radius: 50px;
        background-color: $color-4;
        color: #000 !important;
        margin: 10px 5px;

        &:hover {
            background-color: $color-4;
            color: #000;
        }
    }
}

.image-item {
    @include flex-row-between-start;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    overflow: hidden;

    &-more-image {
        font-size: 18px;
        color: $main-color;
        padding: 15px 50px;

        @include for-size(sm) {
            padding: 5px;
        }
    }

    &-info {
        @include flex-col;
        width: 100%;
        height: 100%;
    }

    &-name {
        margin: auto;
    }

    &-title {
        font-size: 20px;
        padding: 30px 25px;
        margin-bottom: 5px;
    }

    &-dis {
        font-size: 14px;
        padding: 5px 25px;
    }

    &-content {
        width: 100%;
        height: 100%;
        margin-top: 100px;
    }

    &-card {
        @include flex-row;
        width: 100%;
        height: 60px;
        margin: 5px;
        border-radius: 10px;
        background-color: $color-4;
        overflow: hidden;


        &-icon {
            @include flex-col-center;
            height: 100%;
            margin: 0 5px;
            color: $text-secondary;
        }

        &-info {
            height: 100%;

            &-dis {
                font-size: 13px;
                margin: 5px 15px;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;

                a {
                    color: #000 !important;
                }
            }

            &-title {
                font-size: 14px;
                margin: 5px 15px;
                color: $main-color;

            }
        }
    }
}

.photographer-header {
    @include flex-col-center;
    margin: 10px 5px;
    height: 100%;
    position: relative;
    overflow: hidden;

    &-title {
        font-size: 20px;
        color: #000;
        padding: 15px 5px 5px 5px;
    }

    &-count {
        font-size: 14px;
        color: $text-secondary;
    }

    &-avatar {
        @include flex-col-center;
        margin-top: -70px;
        width: 100%;
    }

    &-cover {
        height: 100%;
        width: 100%;
        top: 0;
        object-fit: cover;
        z-index: -1;
        border-radius: 15px;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    }

    &-buts {
        @include flex-row-center;
    }

    &-dropdown {
        @include flex-col-center;
        width: 50px;
        height: 50px;
        border-radius: 100px;

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        &:hover {
            background-color: #eee;
        }

        &:focus-visible {
            background-color: #000;
        }
    }

    &-follow {
        @include flex-row-center;
        background-color: $main-color;
        color: #fff !important;
        margin: 5px;
        width: 100px;
        border-radius: 100px;

        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }
}

.gallery-nav {
    @include flex-row-center;
    overflow: hidden;

    &-content {
        @include flex-row-center;
        position: fixed;
        min-width: 25%;
        bottom: 15px;
        height: 50px;
        border-radius: 15px;
        background-color: #fff;
        z-index: 100;
        box-shadow: $shadow;

        @include for-size('xl') {
            min-width: 30%;
        }

        @include for-size('lg') {
            min-width: 40%;
        }

        @include for-size('md') {
            min-width: 50%;
        }

        @include for-size('sm') {
            min-width: 90%;
        }


        .nav-item {
            .nav-link {
                @include flex-col-center;
                color: $main-color;
            }

            .nav-link.active {
                background-color: $main-color;
                color: #fff;
            }
        }

    }

    &-items {
        @include flex-row-between-center;
        width: 100%;
        margin: 5px 10px;
    }
}

/************* gallery page**************/