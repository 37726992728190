/************* quote page**************/
.quote-page {
    &-card {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: $shadow;
        padding: 15px;
        margin: 15px !important;
    }

    &-search {
        position: absolute;
        top: 10px;
        z-index: 50;

        .search-item {
            @include flex-col-center;
            padding: 5px;

            .neme {
                font-size: 12px;
                color: #000;
            }
        }

        &-content {
            @include flex-row;
            flex-wrap: wrap;
            position: absolute;
            background-color: #fff;
            top: 42px;
            width: 100%;
            z-index: 20;
            padding: 10px;
            border-radius: 15px;
            box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        }

    }

    &-swiper {
        width: 100%;
        height: 550px;


        .swiper-card {
            @include flex-row;
            background-color: $main-color;
            background-image: linear-gradient(to right, $linear);
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            .image-content {
                @include flex-col;
                position: relative;
                justify-content: flex-end;
                overflow: hidden;

                .info-content {
                    position: relative;
                    height: 350px;
                    width: 400px;
                    overflow: hidden;

                    @include for-size(md) {
                        height: 250px;
                        width: 300px;
                    }

                    @include for-size(sm) {
                        height: 150px;
                        width: 200px;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .subtitle {
                        position: absolute;
                        bottom: 0;
                        right: 20px;
                        color: #fff;
                        z-index: 10;
                        overflow: hidden;

                        p:first-child {
                            font-size: 15px;
                            margin: 5px 0;

                            @include for-size(md) {
                                font-size: 13px;
                            }

                            @include for-size(sm) {
                                font-size: 12px;
                            }
                        }

                        p:last-child {
                            font-size: 12px;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;


                            @include for-size(md) {
                                font-size: 10px;
                            }

                            @include for-size(sm) {
                                display: none;
                            }
                        }
                    }

                    .bg-cover {
                        @include absolute-object();
                        background-color: #00000075;
                    }
                }
            }

            .text-content {
                margin: 150px 0 0 0;
                z-index: 15;
                position: relative;
                right: -35px;

                @include for-size(md) {
                    margin: 225px 0 0 0;
                }

                @include for-size(sm) {
                    margin: 200px 0 0 0;
                }

                .icon-content {
                    color: #fff;
                    font-size: 75px;

                    @include for-size(md) {
                        font-size: 60px;
                    }

                    @include for-size(sm) {
                        font-size: 30px;
                    }
                }

                .text {
                    color: #fff;
                    font-size: 25px;
                    width: 100%;

                    @include for-size(md) {
                        font-size: 20px;
                    }

                    @include for-size(sm) {
                        font-size: 16px;
                    }
                }
            }
        }

        .img-top {
            position: absolute;
            top: -75px;
            left: -150px;
            width: 300px;
            height: 300px;
            transform: rotate(90deg);
            z-index: 10;
            object-fit: cover;
            opacity: 0.3;

            @include for-size(sm) {
                display: none;
            }
        }
    }

    &-swiper-quote,
    .card-quote-content {
        padding-top: 50px !important;
        position: relative;
        color: #000;

        .card-quote {
            @include flex-col-between-center;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: $shadow;
            width: 100%;
            height: 300px;
            position: relative;

            .info-content {
                @include flex-col-between-center;
                width: 100%;
                height: 100%;

                .avatar {
                    margin-top: -50px;
                    box-shadow: $shadow;
                }

                .icon-content {
                    @include flex-col-center;
                    @include absolute-object;
                    font-size: 150px;
                    margin: 0;
                    padding: 0;
                    color: rgba(0, 0, 0, 0.1);
                    z-index: 1;
                    opacity: .5;

                    @include for-size(xl) {
                        font-size: 125px;
                    }

                    @include for-size(md) {
                        font-size: 100px;
                    }
                }

                .text {
                    font-size: 18px;
                    text-align: center;
                    z-index: 2;
                    padding: 0 15px;
                    color: #000;

                    @include for-size(xl) {
                        font-size: 16px;
                    }

                    @include for-size(md) {
                        font-size: 14px;
                    }
                }

                .subtitle {
                    font-size: 14px;
                    color: $text-secondary;

                    @include for-size(md) {
                        font-size: 12px;
                    }

                    p {
                        &:last-child {
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

.quote-carousel {
    background-color: $main-color;
    background-image: url('https://jcdn.eu-central-1.linodeobjects.com/website/image/home_image.JPG');
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;

    @include for-size(lg) {
        background-image: none;
    }

    .border-carousel {
        border: 3px solid #ffffff9a;
        z-index: 3;
        border-radius: 15px;
        padding: 0 25px 15px;
        width: 50%;
        position: relative;

        @include for-size(xl) {
            width: 60%;
        }

        @include for-size(lg) {
            width: 65%;
        }

        @include for-size(md) {
            width: 75%;
        }

        @include for-size(sm) {
            width: 90%;
        }
    }

    .bg-cover {
        @include absolute-object();
        background-image: linear-gradient(to right, $main-color, $main-color, rgba(90, 186, 138, .8));
        // z-index: -1;
    }

    .avatar {
        border: 3px solid #ffffff9a;
        margin: 5px;
        margin-top: -60px;
    }

    .name {
        padding-top: 5px;
        margin: 0;
        color: white;
        font-size: 16px;
        z-index: 2;

        @include for-size(md) {
            font-size: 14px;
        }

        @include for-size(sm) {
            font-size: 12px;
        }
    }

    .description {
        padding: 0;
        margin: 0;
        color: white;
        font-size: 14px;
        padding-bottom: 10px;
    }

    .icon {
        font-size: 25px;
        color: white;

        @include for-size(md) {
            font-size: 20px;
        }

        @include for-size(sm) {
            font-size: 16px;
        }
    }

    .icon-dove {
        position: absolute;
        right: -50px;
        top: -120px;
        font-size: 75px;
        color: white;

        @include for-size(sm) {
            font-size: 65px;
        }
    }

    .icon-right {
        position: absolute;
        left: -30px;
        bottom: -40px;
    }

    .icon-left {
        position: absolute;
        right: -30px;
        top: -40px;
    }

    .text-content {
        z-index: 2;
        position: relative;

        .text {
            color: white;
            font-size: 25px;

            @include for-size(lg) {
                font-size: 22px;
            }

            @include for-size(md) {
                font-size: 20px;
            }

            @include for-size(sm) {
                font-size: 16px;
            }
        }
    }

}

/************* quote page**************/