/*-----------------------------
audio player
-------------------------------*/
.rhap_progress-indicator {
    background-color: $main-color;
}

.rhap_main-controls-button {
    color: $main-color;
}

.rhap_volume-button {
    color: $main-color;
}

.rhap_volume-indicator {
    background-color: $main-color;
}

.rhap_button-clear {
    color: $main-color;
}

.audio-player {
    @include flex-row;
    direction: rtl;
    position: fixed;
    box-shadow: $shadow;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 3000;

    &-info {
        @include flex-row;
        align-items: center;
    }

    .player-info {
        @include flex-col;
        justify-content: center;
    }

    p {
        margin: 5px;
        padding: 0;
    }

    &-title {
        font-size: 14px;
    }

    &-author {
        font-size: 12px;
        color: #aaa !important;
    }

    .rhap_container {
        border: none;
        box-shadow: none;
        direction: ltr;
    }
}