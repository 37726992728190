/*-----------------------------
event page
-------------------------------*/

.event-page {
    &-swiper {
        width: 100%;
        height: 500px;
        box-shadow: $shadow;
        border-radius: 15px;

        @include for-size(md) {
            height: 400px;
        }

        @include for-size(sm) {
            height: 300px;
        }

        .swiper-card {
            @include flex-row-between-center;
            background-color: $color-7;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            &::after {
                content: ' ';
                @include absolute-object;
                width: 100%;
                height: 500px;
                z-index: 1;
                background-color: #ffffff83;

                @include for-size(md) {
                    height: 400px;
                }


            }


            .img-avatar {
                width: 400px;
                height: 400px;
                object-fit: cover;
                margin: 0 15px;
                box-shadow: $shadow;
                border-radius: 15px;
                z-index: 2;

                @include for-size(lg) {
                    display: none;
                }

            }

            .image-cover {
                @include absolute-object;
                width: 100%;
                height: 500px;
                z-index: 1;
                object-fit: cover;
                object-position: center -50px;
                opacity: 0.8;

                @include for-size(md) {
                    height: 400px;
                }
            }

            .info-card {
                @include flex-col;
                justify-content: center;
                padding: 10px 50px;
                height: 100%;
                z-index: 2;

                .title-1 {
                    font-size: 13px;
                    color: $main-color;
                    padding-top: 25px;
                }

                .title-2 {
                    font-size: 30px;
                    color: #000;
                    padding: 0 0 25px 0;

                    @include for-size(md) {
                        font-size: 16px;
                        padding: 0 0 5px 0;
                    }
                }

                .title-3 {
                    font-size: 15px;
                    color: #000;
                    width: 65%;
                    text-align: justify;

                    @include for-size(md) {
                        font-size: 12px;
                        width: 100%;
                    }
                }

                .title-4 {
                    font-size: 14px;
                    color: #000;
                    padding-top: 20px;

                    @include for-size(md) {
                        font-size: 12px;
                        width: 100%;
                    }
                }

                .info-but {
                    width: 200px;
                    color: #fff;
                    background-color: $main-color;
                    border-radius: 10px;
                    box-shadow: $shadow;
                    transition: all 0.5s ease-in-out;

                    @include for-size(md) {
                        font-size: 14px;
                    }

                    @include for-size(sm) {
                        font-size: 12px;
                        width: 100%;
                    }

                    &:hover {
                        transform: scale(1.02);
                    }
                }
            }
        }
    }

    .event-card-h {
        min-height: 500px;
    }

    .event-section {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: $shadow;
        margin: 5px;
        padding: 5px;


        .collapse-but {
            width: 200px;
            color: #fff;
            background-color: $main-color;
            border-radius: 10px;
            box-shadow: $shadow;
            transition: all 0.5s ease-in-out;

            &:hover {
                transform: scale(1.02);
            }
        }

        .title-section {
            font-size: 18px;
            padding: 15px;
            font-family: 'jamalBold';
        }

        .event-content {
            height: 400px;
            margin: 5px;
            padding: 10px;
        }
    }

    .calendar-card {
        min-height: 400px;
        margin: 5px;
        padding: 5px;
        // overflow: hidden;

        .taps-content {
            overflow-y: scroll;
        }

        .tap-event {
            @include flex-row;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid $third-color;

            .nav-item {
                margin: 0;
                padding: 10px 25px 0;

                @include for-size(xxl) {
                    padding: 10px 3px 0;
                }

                @include for-size(xl) {
                    padding: 10px 25px 0;
                }

                @include for-size(sm) {
                    padding: 10px 0 0;
                }

                .border-p {
                    width: 1px;
                    height: 25px;
                    background-color: $third-color;
                }

                .nav-link {
                    margin: 0;
                    color: #000;
                    font-size: 14px !important;

                    @include for-size(sm) {
                        font-size: 12px !important;
                    }
                }

                .nav-link.active {
                    color: #000;
                    border-bottom: 2px solid $main-color;
                }
            }
        }

        .calendar {
            direction: ltr;
            width: 100%;
            height: 100%;
            margin: 10px;
            border: none;
            box-shadow: none;
        }
    }
}

.day-card {
    @include flex-row;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    margin: 10px 5px;
    width: 100%;
    box-shadow: $shadow;

    .timer {
        @include flex-col;
        justify-content: center;
        background-image: url('../../svg/deat.svg');
        background-size: 110px 100px;
        width: 100px;
        height: 100px;
        border-radius: 15px;
        margin: 5px;

        .name {
            font-size: 14px;
            color: #fff;
            padding: 0 5px;
            margin: 0;
        }

        .number {
            font-size: 30px;
            padding: 0 10px;
            color: #fff;
            margin: 0;
        }
    }

    .info-card {
        width: 75%;

        @include for-size(md) {
            width: 75%;
        }

        @include for-size(sm) {
            width: 70%;
        }

        .title-1 {
        font-size: 13px;
            color: #000;
            margin: 0;
            padding: 5px;

            @include for-size(md) {
                font-size: 12px;
            }

            @include for-size(sm) {
                font-size: 10px;
            }
        }

        .title-2 {
            font-size: 18px;
            margin: 0;
            padding: 5px;
            color: #000;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include for-size(md) {
                font-size: 16px;
            }

            @include for-size(sm) {
                font-size: 14px;
            }

            a {
                position: relative;
                color: #000;
            }
        }

        .date {
            font-size: 13px;
            color: #fff;
            margin: 0;
            padding: 5px;

            @include for-size(md) {
                font-size: 11px;
            }

            @include for-size(sm) {
                font-size: 10px;
            }
        }
    }
}

.event-modal {
    height: 400px;
    margin: 5px;
    padding: 10px;
    overflow-y: scroll;

}



/************* event page**************/