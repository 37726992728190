/*-----------------------------
navbar
-------------------------------*/
.dropdown-toggle {
    border: none !important;

    &:hover {
        .dropdown-menu {
            display: block !important;
        }
    }

    &::after {
        margin-right: 5px !important;
    }
}

.dropdown-menu {
    text-align: inherit !important;
    font-size: 14px !important;
    z-index: 10000 !important;
}

.dropdown-icon {
    font-size: 12px;
    margin-right: 5px;
}

.dropdown-icon_page {
    color: $color-6 !important;
}

.dropdown-bot {
    font-size: 14px;
    margin-left: 5px;
    color: $color-6;

    @include for-size(xxl) {
        font-size: 12px !important;
    }
}

.dropdown-bot-min {
    font-size: 14px;
    margin-left: 5px;
    color: $color-6 !important;

    @include for-size(xxl) {
        font-size: 12px !important;
    }
}

.dropdown-bot_page {
    font-size: 14px;
    margin-left: 5px;
    color: $color-7 !important;

    @include for-size(xxl) {
        font-size: 12px !important;
    }
}

.navbar-content {
    position: relative;
    z-index: 1000;
    transition: all 0.5 ease-in-out;
}

.nav-one {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $background-color-6;
    padding: 0 150px;
}

.footer-bottom {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $background-color-6;
    padding: 0 150px;
}

.icons-navbar {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    a {
        margin: 0 10px;
        color: $color-6;
    }
}

.fixed-top {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    height: 60px !important;
    font-size: 14px !important;
    z-index: 1000 !important;
    background-color: $background-color-7 !important;
    opacity: 1 !important;
    transition: all 0.5s ease-in-out;
}

.nav-two {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: $background-color-8;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    text-align: center;
    border: 1px solid #CED6E0;
    border-left: 0px;
    border-right: 0px;
    z-index: 0;
    box-sizing: border-box;
    font-family: 'jamalBold';

    li {
        list-style: none;
        display: inline-block;
        padding: 0px 2.3vw;
        border-left: 1px solid #CED6E0;
        margin: 6px 0px;
        position: relative;

        &:hover {
            &::after {
                padding-left: 50%;
                padding-right: 50%;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 0px;
            height: 3px;
            background-color: $main-color;
            bottom: -10px;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.5s ease-in-out;
        }


        &:last-child {
            border-left: 0px solid rgba(16, 152, 87, 0.3);
            margin-left: 0px;
        }
    }

    a {
        color: $color-8;
        font-weight: 300;
        font-size: 15px;
        text-decoration: none;

    }
}

.nav-three_page {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute !important;
    width: 100%;
    height: 40px !important;
    bottom: -41px !important;
    background-color: $background-color-9 !important;
    transition: all 0.5s ease-in-out;
    font-weight: 500;
    box-sizing: border-box;
    box-shadow: none !important;

    .logo-nav {
        a {
            width: 100%;
            overflow: hidden;

            p {
                white-space: nowrap;
                margin-right: -10px;
                font-size: 20px;
            }
        }
    }
}

.nav-three {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    bottom: 0;
    background-color: $background-color-7;
    height: 60px;
    box-shadow: 0px 4px 5.7px -7.2px #000;

    .logo-nav {
        a {
            width: 100%;
            overflow: hidden;

            p {
                white-space: nowrap;
                font-size: 20px;
                margin-top: 6px;
                margin-right: -10px;
            }
        }
    }

    nav {
        width: 100%;
        margin: auto;
        padding-left: 25px;
        display: flex;
        justify-content: flex-end;

        ul {
            display: inline-block;
            padding: 0;
            margin: 0;
            font-family: 'jamalBold';
            transition: all 0.3s;

            li {
                display: inline-block;
                margin: 0;
                vertical-align: middle;
                padding: 5px;
                position: relative;
                cursor: pointer;

                @include for-size(xxl) {
                    padding: 0;
                }

                &:hover {
                    &::after {
                        padding-left: 50%;
                        padding-right: 50%;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 0px;
                    height: 3px;
                    background-color: $main-color;
                    border-radius: 5px;
                    bottom: 0px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.5s ease-in-out;
                }

                a {
                    color: $color-6;
                    font-size: 14px;

                    @include for-size(xxl) {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }

    .image-nav {
        width: 100px;
        margin: 5px;
    }
}

.logo-nav {
    @include flex-col-center();
    position: relative;
    margin: 5px 50px 0 0;
    font-family: 'jamalBold';

    a {
        p {
            padding-right: 5px;
            font-size: 20px !important;
        }
    }
}

.logo-text {
    margin-right: 15px;
    font-size: 20px;
    color: $color-7;
}


.navbar-min {
    display: none;

    @include for-size(xl) {
        display: block !important;
    }
}

.navbar-max {
    @include for-size(xl) {
        display: none !important;
    }
}

.offcanvas {
    .btn-close {
       position: absolute;
       top: 15px;
       left: 10px;
    }
}

.image-nav-min {
    width: 100px;
}

.nav-min {
    color: $color-6 !important;
    background-color: $background-color-7;
    width: 100% !important;

    a {
        padding: 1em 1.25em;
        font-size: 15px !important;
    }
}

.navbar-toggler {
    border: none !important;
    box-shadow: none !important;
}