   .but-home {
       @include flex-row-center;
       text-align: center;
       align-self: center;
       font-size: 18px;
       padding: 22px 20px !important;
       color: #fff;
       background-color: $main-color;
       z-index: 10;
       border: none !important;
       transition: all .2s ease-in-out;

       & i {
           padding: 5px;
       }

       &:hover {
           color: #fff !important;
           background-color: $second-color !important;
           transform: scale(1.05);
       }
   }

   .but-more {
       @include flex-row-center;
       @include main-button;
       padding: 10px 25px;
       border: none;
       transition: all 0.5s ease-in-out;
       font-size: 16px;

       &:hover {
           transform: scale(1.05);
       }
   }

   .but-flow {
       @include flex-row-center;
       @include main-button;
       padding: 5px 15px;
       margin: 5px 10px;
       border-radius: 15px;

       @include for-size(sm) {
           font-size: 12px !important;
           padding: 3px 10px;
           margin: 0;
       }
   }

   .but-home_op {
       display: none;

       @include for-size(sm) {
           display: flex;
       }
   }