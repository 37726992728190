/************* loading **************/
.loading {
    @include flex-col-center;
    width: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    //$main-color,#5aba8a
    background: linear-gradient(45deg, #5aba8a 0%, $main-color 29%, #09a188 66%, $main-color 100%);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
    overflow: hidden;

    &::before,
    &::after {
        content: "";
        width: 70vmax;
        height: 70vmax;
        position: absolute;
        background: rgba(255, 255, 255, 0.07);
        left: -20vmin;
        top: -20vmin;
        animation: morph 15s linear infinite alternate, spin 20s linear infinite;
        z-index: 1;
        will-change: border-radius, transform;
        transform-origin: 55% 55%;
        pointer-events: none;
    }

    &::after {
        width: 70vmin;
        height: 70vmin;
        left: auto;
        right: -10vmin;
        top: auto;
        bottom: 0;
        animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
        transform-origin: 20% 20%;
    }


    .st0 {
        display: none;
    }

    .st1 {
        display: inline;
    }

    .st2 {
        opacity: 0.29;
    }

    .st3 {
        fill: #FFFFFF;
    }

    .st4 {
        clip-path: url(#SVGID_2_);
        fill: #FFFFFF;
    }

    .st5 {
        clip-path: url(#SVGID_4_);
    }

    .st6 {
        clip-path: url(#SVGID_6_);
    }

    .st7 {
        clip-path: url(#SVGID_8_);
    }

    .st8 {
        clip-path: url(#SVGID_10_);
    }

    .st9 {
        fill: none;
    }

    .st10 {
        clip-path: url(#SVGID_12_);
    }

    .st11 {
        opacity: 0.7;
    }

    .st12 {
        clip-path: url(#SVGID_14_);
    }

    .st13 {
        opacity: 0.2;
    }

    .st14 {
        clip-path: url(#SVGID_16_);
    }

    .st15 {
        opacity: 0.3;
        fill: #FFFFFF;
        enable-background: new;
    }

    .loading-image {
        @include flex-col-center;
        width: 100%;
        // animation: slide-in-bck-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
        color: #fff;
    }
}