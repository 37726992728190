/************* empty **************/
.empty {
    @include flex-col-center;
    width: 100%;
    height: 100%;
    padding: 50px 10px;

    &-icon {
        font-size: 100px;
        color: #aaa;
        margin: 0;
        padding: 0;
    }

    &-title {
        font-size: 20px;
        color: #aaa;
        margin: 0;
        padding: 0;
    }
}