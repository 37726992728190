@font-face {
    font-family: 'jamalBold';
    src: url('../../font/RB-Bold.woff2') format('woff2');
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'jamalRegular';
    src: url('../../font/RB-Light.woff2') format('woff2');
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Uthman_Taha';
    src: url('../../font/Uthman_Taha.woff') format('woff');
    font-style: normal;
    font-display: auto;
}
.f-bold {
    font-family: 'jamalBold';
}
.border-title {
    border-right: 4px solid $main-color !important;
    padding-right: 5px;
}
.main-color{
color:$main-color;
}