/*-----------------------------
footer
-------------------------------*/
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 15px 5%;
    background-color: $background-color-7;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 2px 2px 10px rgba(128, 128, 128, 0.212);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 450px;
    z-index: 1000;
}

.footer-content {
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.footer-links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
}

.footer-links-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 25px 100px;

    p {
        color: $color-9 !important;
    }

    a {
        font-size: 14px;
        color: $color-10 !important;
        padding: 5px 15px 0 0;

        &:hover {
            color: $color-9 !important;
        }
    }
}

.footer-title-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 25px;

    p {
        font-size: 14px;
        color: $color-10 !important;
        padding: 5px;
    }

    .footer-text {
        font-size: 18px;
        color: $color-10 !important;
        margin: 10px 0;
    }
}

.logo-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.image-footer {
    width: 150px;
    object-fit: contain;
}

.main-footer {
    width: 100%;
    position: relative;
    margin-top: 150px;
    background-color: var(--body-color);

    .footer-header {
        background-color: #efefef;
        height: 200px;
        position: relative;
        @include flex-col-center;
        margin: 0;
        padding: 0;

        .list {
            @include flex-row;
            align-items: flex-end;
            height: 100%;

            .count {
                @include flex-col-center;
                margin: 10px 50px;

                .number {
                    font-size: 30px;

                    @include for-size(sm) {
                        font-size: 25px;
                    }
                }

                .info {
                    font-size: 14px;

                    @include for-size(sm) {
                        font-size: 12px;
                    }
                }
            }
        }

        .logo-footer {
            position: absolute;
            top: -60px;
            background-color: #efefef;
            border: 8px solid white;
            width: 150px;
            height: 120px;
            border-radius: 15px;
            transition: all 0.5s ease-in-out;

            &:hover {
                background-color: #fff;
            }
        }
    }

    .footer-map {
        background-image: linear-gradient(to bottom, #23b5ab, #5aba8a);
        position: relative;
        @include flex-col-center;
        margin: 0;
        padding: 0;

        .copy {
            color: #fff;
        }

        .site-map {
            @include flex-row-center;
            width: 100%;
            flex-wrap: wrap;
            margin-bottom: 50px;
            margin-top: 20px;

            a {
                color: #fff;
                font-size: 18px;
                position: relative;
                list-style: none;
                padding: 0px 2vw;
                border-left: 1px solid #CED6E0;
                margin: 6px 0px;
                position: relative;
                transition: all 0.2s ease-in-out;

                &:last-child {
                    border-left: 0px solid rgba(16, 152, 87, 0.3);
                    margin-left: 0px;
                }

            }
        }

        .icons-navbar {
            @include flex-row-center;
            margin: 15px;

            a {
                width: 40px;
                height: 40px;
                background-color: #efefef;
                border-radius: 35px;
                font-size: 20px;
                @include flex-row-center;
                transition: all 0.2s ease-in-out;
                position: relative;


            }

            .facebook {
                color: #1778f2;

                &:hover {
                    color: #fff;
                    background-color: #1778f2;
                }
            }

            .instagram {
                color: #E1306C;

                &:hover {
                    color: #fff;
                    background-color: #E1306C;
                }
            }

            .telegram {
                color: #229ED9;

                &:hover {
                    color: #fff;
                    background-color: #229ED9;
                }
            }

            .twitter {
                color: #14171a;

                &:hover {
                    color: #fff;
                    background-color: #14171a;
                }
            }

            .youtube {
                color: #FF0000;

                &:hover {
                    color: #fff;
                    background-color: #FF0000;
                }
            }

        }
    }
}